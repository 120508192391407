import React from 'react';
import { Row, Col, Button } from 'reactstrap';

// import { Button as Button } from "@material-ui/core"

const ButtonsSaveClose = ({
  toggle,
  labelSave,
  labelBack,
  isDisabled,
  inverted = false,
  iconSave = <i className='fas fa-save'></i>,
  onSave,
  onBack
}) => {
  const btn1 = () => (
    <Button onClick={onSave} color='success' block disabled={isDisabled}>
      {!inverted && iconSave} {labelSave} {inverted && iconSave}
    </Button>
  );

  const btn2 = () => (
    <Button color='secondary' block onClick={onBack}>
      <i className={`fas fa-${toggle ? 'chevron-left' : 'times'}`}></i>{' '}
      {labelBack}
    </Button>
  );

  return (
    <Row className='justify-content-end'>
      <Col lg={2}>{inverted ? btn2() : btn1()}</Col>
      <Col lg={2}>{inverted ? btn1() : btn2()}</Col>
    </Row>
  );
};

export default ButtonsSaveClose;
