import { Redirect } from 'react-router-dom';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import config, { authGraphqlHeaders } from './index';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const forbiddenError = graphQLErrors.find(
      ({ extensions }) => extensions.code === 'FORBIDDEN'
    );

    if (forbiddenError) {
      <Redirect to='/logout' />;
    }
  }
});

const httpLink = createHttpLink({
  uri: config.URL_API
});

const authLink = setContext((_, { headers }) => {
  return authGraphqlHeaders(headers);
});

const client = new ApolloClient({
  uri: config.URL_API,
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache()
});

export default client;
