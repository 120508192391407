import { request as graphql } from 'graphql-request';
import { getQueryPayments } from '../queries/payments';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_PAYMENTS: 'payments/SET',
  SET_ERROR: 'payments/ERROR',
  SET_SUCCESS: 'payments/SUCCESS'
};

const INITIAL_STATE = [];

export default function payments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PAYMENTS:
      return action.payments;
    case Types.SET_ERROR:
      return action.error;
    case Types.SET_SUCCESS:
      return action.success;
    default:
      return state;
  }
}

const setPayments = payments => ({
  type: Types.SET_PAYMENTS,
  payments
});

const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setPayments,
  setError,
  setSuccess,
  getListPayments: customer => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryPayments(customer),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { payments } = data;

          if (payments) {
            dispatch(setPayments(payments));
          } else {
            dispatch(
              setError({
                error: 'Houve um erro ao retornar as condição de pagamento'
              })
            );
          }
        })
        .catch(error => {
          dispatch(
            setError({
              error: 'Houve um erro ao retornar as condição de pagamento'
            })
          );
        });
    };
  }
};
