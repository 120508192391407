import { gql } from '@apollo/client';

export const getQueryCategory = (id, type = null) => `
{
  categoryCustomer(customer: "${id}", type: ${type} ){
    _id name categoryprincipal createdAd
    customer{ _id name }
    parentCategory{ _id name }
    updatedAt
    visibleOnApp
    ref
    type
  }
}
`;
export const getQueryCategoryType = (id, type = null) => `
{
  categoryCustomerType(customer: "${id}", type: ${type} ){
    _id name categoryprincipal createdAd
    customer{ _id name }
    parentCategory{ _id name }
    updatedAt
    visibleOnApp
    ref
    type
  }
}
`;
export const setQueryCategory = (
  name,
  description,
  image,
  active,
  parentCategory,
  customer,
  principal
) => `
mutation{
    addCategory(
        name: "${name}"
        description: "${description}"
        image: "${image}"
        active: ${active}
        customer: "${customer}"
        categoryprincipal: ${true}
    ){
        _id name visibleOnApp type
    }
}`;

export const delQueryCategory = id => `
mutation{
    removeCategory(
        _id: "${id}"
        ){
            success message
    }
  }`;

export const deleteQueryCustomer = id => `
mutation{
	removeCustomer(
        _id: "${id}"
        ){
    success message

  }
}
`;

export const getQueryCategoryId = id => `
{
  category(_id: "${id}"){
    _id name description image visibleOnApp ref type parentCategory{ _id name }
  }
}`;

export const updateQueryCategory = (
  id,
  name,
  description,
  image,
  active,
  parentCategory,
  principal
) => `
mutation{
  updateCategory(
    _id: "${id}",
    name: "${name}"
    description: "${description}"
    image: "${image}"
    active: ${active}
    categoryprincipal: ${true}
  ) { success message }
}
`;

export const GET_CATEGORY = gql`
  query getCategory($_id: ID!) {
    category(_id: $_id) {
      _id
      name
      description
      image
      visibleOnApp
      ref
      type
      parentCategory {
        _id
        name
      }
    }
  }
`;

export const GET_CATEGORIES_CUSTOMER = gql`
  query getCategories($customer: ID!, $type: TypeCategory) {
    categoryCustomer(customer: $customer, type: $type) {
      _id
      name
      description
      image
      visibleOnApp
      ref
      type
      parentCategory {
        _id
        name
      }
      products {
        _id
        name
      }
    }
  }
`;

export const GET_CATEGORIES_CUSTOMER_TYPE = gql`
  query getcategoryCustomerType($customer: ID!, $type: [TypeCategory]) {
    categoryCustomerType(customer: $customer, type: $type) {
      _id
      name
      description
      image
      visibleOnApp
      ref
      type
      parentCategory {
        _id
        name
      }
      products {
        _id
        name
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation addCategory(
    $name: String
    $description: String
    $image: String
    $active: Boolean
    $categoryprincipal: Boolean
    $customer: ID
    $parentCategory: ID
    $visibleOnApp: Boolean
    $ref: String
    $type: TypeCategory
  ) {
    addCategory(
      name: $name
      description: $description
      image: $image
      active: $active
      categoryprincipal: $categoryprincipal
      customer: $customer
      parentCategory: $parentCategory
      visibleOnApp: $visibleOnApp
      ref: $ref
      type: $type
    ) {
      _id
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $_id: ID
    $name: String
    $description: String
    $image: String
    $active: Boolean
    $categoryprincipal: Boolean
    $customer: ID
    $parentCategory: ID
    $visibleOnApp: Boolean
    $ref: String
    $type: TypeCategory
  ) {
    updateCategory(
      _id: $_id
      name: $name
      description: $description
      image: $image
      active: $active
      categoryprincipal: $categoryprincipal
      customer: $customer
      parentCategory: $parentCategory
      visibleOnApp: $visibleOnApp
      ref: $ref
      type: $type
    ) {
      success
    }
  }
`;
