import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse,
  Input,
  Alert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import { GET_CLIENTS_FILTER_PAGE } from '../../../store/queries/clients';
import { GET_COMTELE_ACCOUNT_BALANCE } from '../../../store/queries/comtele';
import { useLazyQuery, useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import MaterialTable from '../../../components/Table/MaterialTable';
import { Required } from './styles';
import Icon from '@mdi/react';
import { mdiFilter, mdiFilterOutline } from '@mdi/js';
import { Button } from '../../../components/Form/Button';
import dayjs from 'dayjs';
import SelectEmpresa from '../../../components/Select/SelectEmpresa';
import { compareDate, formatDate, getDateObject } from '../../../helpers/date';
import { FilterIcon } from '../../../components/Table/ActionItems';
import SelectProductGas from '../../../components/Select/SelectProductGas';
import toast from '../../../helpers/toast';
import InputMask from 'react-input-mask';
import SelectGender from '../../../components/Select/SelectGender';
import SelectAgeRange from '../../../components/Select/SelectAgeRange';
import './style.css';
const createdAtDateFormat = 'DD/MM/YYYY';
const clientBirthdayFormat = 'DD/MM';
const SORTABLE_COLUMNS = [
  'name',
  'email',
  'sexo',
  'birthdayMMDD',
  'phone',
  'createdAt'
];
// const FILTERABLE_COLUMNS = ['name', 'email', 'sexo', 'phone'];

const GENDERS = [
  {
    value: 'todos',
    label: 'Todos'
  },
  {
    value: 'Masculino',
    label: 'Masculino'
  },
  {
    value: 'Feminino',
    label: 'Feminino'
  },
  {
    value: 'Prefiro não responder',
    label: 'Prefiro não responder'
  },
  {
    value: 'Outros',
    label: 'Outros'
  },
  {
    value: null,
    label: 'Não informado'
  }
];
const AGE_RANGE = [
  {
    value: 'DE_18_A_24_ANOS',
    label: 'De 18 a 24 anos'
  },
  {
    value: 'DE_25_A_34_ANOS',
    label: 'De 25 a 34 anos'
  },
  {
    value: 'DE_35_A_44_ANOS',
    label: 'De 35 a 44 anos'
  },
  {
    value: 'DE_45_A_54_ANOS',
    label: 'De 45 a 54 anos'
  },
  {
    value: 'DE_55_A_64_ANOS',
    label: 'De 55 a 64 anos'
  },
  {
    value: 'DE_65_ANOS_OU_MAIS',
    label: '65 anos ou mais'
  }
];
const FILTERABLE_COLUMNS = ['name', 'email', 'sexo', 'phone'];

const BIRTHDAY_RANGE = [
  {
    value: 'DIA',
    label: 'Aniversariantes do dia'
  },
  {
    value: 'SEMANA',
    label: 'Aniversariantes da semana'
  },
  {
    value: 'MES',
    label: 'Aniversariantes do mês'
  }
];

const columnHeadClient = [
  {
    title: <FilterIcon>Nome</FilterIcon>,
    field: 'name',
    width: 200,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: <FilterIcon>E-mail</FilterIcon>,
    field: 'email',
    width: 200,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: <FilterIcon>Sexo</FilterIcon>,
    field: 'sexo',
    width: 120,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: <FilterIcon>Data de Aniversário</FilterIcon>,
    field: 'birthdayMMDD',
    width: 100,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: <FilterIcon>Telefone</FilterIcon>,
    field: 'phone',
    width: 100,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Data de Cadastro',
    field: 'createdAt',
    customSort: (a, b) => {
      const A = getDateObject({
        date: a.createdAt,
        fromFormat: createdAtDateFormat
      });
      const B = getDateObject({
        date: b.createdAt,
        fromFormat: createdAtDateFormat
      });
      return compareDate(A, B);
    },
    width: 80,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  }
];
function createData(
  id,
  name,
  email,
  birthday,
  sexo,
  phone,
  createdAt,
  isChecked
) {
  return {
    id,
    name,
    email,
    birthdayMMDD: birthday,
    sexo,
    phone,
    createdAt,
    tableData: {
      checked: isChecked
    }
  };
}
export const ClientsSelection = ({
  gasstation,
  store,
  productgas,
  user,
  modalMode = false,
  defaultSelectedProducts,
  defaultSelectedGasStations,
  disableDefaultSelectedOptions = false,
  disableDefaultSelectedOptionsMessage,
  sendType: type,
  onSelectionConfirm
}) => {
  const getDate = days => dayjs().utc().add(days, 'days').format('YYYY-MM-DD');
  const SALE_TIME_RANGE = [
    {
      label: 'Períodos Pré-Estabelecidos',
      options: [
        {
          value: 'Compraram nos últimos 45 dias',
          action: 'COMPRARAM',
          label: 'Compraram nos últimos 45 dias',
          startDate: getDate(-45),
          endDate: getDate(0)
        },
        {
          value: 'Não compram de 46 a 60 dias',
          action: 'NAO_COMPRARAM',
          label: 'Não compram de 46 a 60 dias',
          startDate: getDate(-60),
          endDate: getDate(-46)
        },
        {
          value: 'Não compram de 61 a 90 dias',
          action: 'NAO_COMPRARAM',
          label: 'Não compram de 61 a 90 dias',
          startDate: getDate(-90),
          endDate: getDate(-61)
        },
        {
          value: 'Não compram há mais de 90 dias',
          action: 'NAO_COMPRARAM',
          label: 'Não compram há mais de 90 dias',
          startDate: getDate(-91),
          endDate: null
        },
        {
          value: 'Nunca compraram',
          action: 'NUNCA_COMPRARAM',
          label: 'Nunca compraram',
          startDate: null,
          endDate: null
        }
      ]
    },
    {
      label: 'Períodos Personalizados',
      options: [
        {
          label: 'Compraram nos últimos X dias',
          value: 'DIA',
          action: 'COMPRARAM'
        },
        {
          label: 'Não compraram há mais de X dias',
          value: 'DIA',
          action: 'NAO_COMPRARAM'
        }
      ]
    }
  ];
  const [sendType, setSendType] = useState('');
  const url =
    window.location.pathname.split('/')[
      window.location.pathname.split('/').length - 2
    ];
  const [listDataTable, setListDataTable] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([
    { value: 'todos', label: 'Todos' }
  ]);
  const [selectedAgeRanges, setSelectedAgeRanges] = useState([
    { value: 'todos', label: 'Todas as Faixas Etárias' }
  ]);
  const [selectedBirthdayRanges, setSelectedBirthdayRages] = useState(null);
  const [selectedSaleTimes, setSelectedSaleTimes] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [selectedProductgas, setSelectedProductgas] = useState([]);
  const [startDate, setStartDate] = useState('', 'startDate');
  const [endDate, setEndDate] = useState('', 'endDate');
  const [days, setDays] = useState('', 'days');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [direction, setDirection] = useState('desc');
  const [search, setSearch] = useState('');
  const [customFilter, setCustomFilter] = useState(true);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [balance, setBalance] = useState(0);
  const [called, setCalled] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [filterClients, { loading, data, error }] = useLazyQuery(
    GET_CLIENTS_FILTER_PAGE,
    {
      fetchPolicy: 'network-only'
    }
  );
  useEffect(() => {
    const getData =
      !!selectedGenders.length ||
      !!selectedAgeRanges ||
      !!selectedBirthdayRanges ||
      !!selectedSaleTimes ||
      !!selectedEmpresas ||
      !!selectedProductgas;
    if (getData) {
      handleSearch();
      setCalled(true);
    }
  }, []);

  useEffect(() => {
    if (defaultSelectedGasStations && defaultSelectedGasStations.length) {
      setSelectedEmpresas(defaultSelectedGasStations);
    }
  }, [defaultSelectedGasStations]);
  useEffect(() => {
    if (defaultSelectedProducts && defaultSelectedProducts.length) {
      setSelectedProductgas(defaultSelectedProducts);
    }
  }, [defaultSelectedProducts]);

  async function loadAllClients(page = 0, allClients = []) {
    try {
      const perPage = 9999;
      const customDateOpt = selectedSaleTimes.find(
        x => x.value === 'DATA' || x.value === 'DIA'
      );
      let newSaleRange = null;
      if (customDateOpt) {
        newSaleRange = [
          {
            action: customDateOpt.action,
            startDate,
            endDate
          }
        ];
      }

      const inputs = {
        page,
        perPage,
        orderBy,
        orderDirection: direction,
        search,
        searchFields: FILTERABLE_COLUMNS,
        customer: user.customer,
        ageRange: selectedAgeRanges.some(x => x.value === 'todos')
          ? []
          : selectedAgeRanges
              .filter(x => x.value !== 'todos')
              .map(item => item.value),
        birthdayRange:
          selectedBirthdayRanges && selectedBirthdayRanges.length
            ? selectedBirthdayRanges[0].value
            : null,
        boughtRange:
          newSaleRange ||
          selectedSaleTimes.map(item => ({
            action: item.action,
            startDate: item.startDate,
            endDate: item.endDate
          })),
        sexo: selectedGenders.find(x => x.value === 'todos')
          ? []
          : selectedGenders
              .filter(x => x.value !== 'todos')
              .map(item => item.value),
        boughtGasStations: selectedEmpresas.some(x => x.value === 'todos')
          ? gasstation.map(item => item._id)
          : selectedEmpresas
              .filter(x => x.type === 'gasstation' && x.value !== 'todos')
              .map(item => item.value),
        boughtStores: selectedEmpresas.some(x => x.value === 'todos')
          ? store.map(item => item._id)
          : selectedEmpresas
              .filter(x => x.type === 'store' && x.value !== 'todos')
              .map(item => item.value),
        boughtProductgas: selectedProductgas.some(x => x.value === null)
          ? productgas.filter(x => x.active && x.value).map(item => item._id)
          : selectedProductgas.map(item => item.value)
      };

      const response = await filterClients({
        variables: {
          inputs
        }
      });
      const { docs, totalPages } = response.data.clientsFilterPage;

      const updateClients = [...allClients, ...docs];

      if (page < totalPages) {
        loadAllClients(page + 1, updateClients);
      } else {
        const clientsId = updateClients.map(client => client._id);
        setAllClients(clientsId);
        setSelectedIDs([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function confirm() {
    if (onSelectionConfirm) {
      if (selectedIDs.length > 0) {
        onSelectionConfirm({
          ...getFiltersState(),
          sendType,
          idClients: selectedIDs,
          totalClients: selectedIDs.length || 0
        });
      }
      if (allClients.length > 0) {
        onSelectionConfirm({
          ...getFiltersState(),
          sendType,
          totalClients: allClients.length || 0,
          idClients: allClients
        });
      }
    }
  }

  function addNewItem(list) {
    const newRows = [];
    list.map(item => {
      const isChecked = selectedIDs.includes(item._id);
      newRows.push(
        createData(
          item._id,
          item.name,
          item.email,
          item.birthday && dayjs(item.birthday).isValid()
            ? dayjs(item.birthday).format(clientBirthdayFormat)
            : '',
          item.sexo,
          item.phone,
          formatDate({
            date: item.createdAt,
            format: createdAtDateFormat
          }),
          isChecked
        )
      );
    });
    return newRows;
  }

  function handleSelectionChange(rows) {
    const ids = rows ? rows.map(item => item.id) : [];

    const newIds = [...new Set([...ids, ...selectedIDs])];

    if (newIds.length > 100) {
      toast.warning('Selecione no máximo 100 Clientes');
      addNewItem(data.clientsFilterPage.docs);
      return;
    }
    setAllClients([]);
    setSelectedIDs(newIds);
  }

  const isCustomSaleTimeSelected = useMemo(() => {
    setEndDate(null);
    return selectedSaleTimes && !!selectedSaleTimes.find(x => !x.value);
  }, [selectedSaleTimes]);

  const { data: dataBalance } = useQuery(GET_COMTELE_ACCOUNT_BALANCE, {
    variables: {
      customer: user.customer
    }
  });

  useEffect(() => {
    if (
      dataBalance &&
      dataBalance.getComteleAccountBalance &&
      dataBalance.getComteleAccountBalance.balance
    ) {
      const account = dataBalance.getComteleAccountBalance;
      setBalance(account.balance);
    } else {
      setBalance(0);
    }
  }, [dataBalance]);

  function fetchMore() {
    filterClients({
      variables: {
        inputs: {
          page,
          perPage: pageSize,
          orderBy,
          orderDirection: direction,
          search,
          searchFields: FILTERABLE_COLUMNS,
          customer: user.customer
        }
      }
    });
  }
  function fetchMoreFilter() {
    const customDateOpt = selectedSaleTimes.find(
      x => x.value === 'DATA' || x.value === 'DIA'
    );
    let newSaleRange = null;
    if (customDateOpt) {
      newSaleRange = [
        {
          action: customDateOpt.action,
          startDate,
          endDate
        }
      ];
    }

    const inputs = {
      page,
      perPage: pageSize,
      orderBy,
      orderDirection: direction,
      search,
      searchFields: FILTERABLE_COLUMNS,
      customer: user.customer,
      ageRange: selectedAgeRanges.some(x => x.value === 'todos')
        ? []
        : selectedAgeRanges
            .filter(x => x.value !== 'todos')
            .map(item => item.value),
      birthdayRange:
        selectedBirthdayRanges && selectedBirthdayRanges.length
          ? selectedBirthdayRanges[0].value
          : null,
      boughtRange:
        newSaleRange ||
        selectedSaleTimes.map(item => ({
          action: item.action,
          startDate: item.startDate,
          endDate: item.endDate
        })),
      sexo: selectedGenders.find(x => x.value === 'todos')
        ? []
        : selectedGenders
            .filter(x => x.value !== 'todos')
            .map(item => item.value),
      boughtGasStations: selectedEmpresas.some(x => x.value === 'todos')
        ? gasstation.map(item => item._id)
        : selectedEmpresas
            .filter(x => x.type === 'gasstation' && x.value !== 'todos')
            .map(item => item.value),
      boughtStores: selectedEmpresas.some(x => x.value === 'todos')
        ? store.map(item => item._id)
        : selectedEmpresas
            .filter(x => x.type === 'store' && x.value !== 'todos')
            .map(item => item.value),
      boughtProductgas: selectedProductgas.some(x => x.value === null)
        ? productgas.filter(x => x.active && x.value).map(item => item._id)
        : selectedProductgas.map(item => item.value)
    };

    filterClients({
      variables: {
        inputs
      }
    });
  }
  function scrollToTable() {
    document.querySelector('#table-clients').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const CheckIcon = ({ isChecked, onClick }) => {
    return isChecked ? (
      <Icon onClick={onClick} path={mdiFilter} size={0.75} />
    ) : (
      <Icon onClick={onClick} path={mdiFilterOutline} size={0.75} />
    );
  };

  function getFiltersState() {
    const customDateOpt = selectedSaleTimes.find(
      x => x.value === 'DATA' || x.value === 'DIA'
    );
    let newSaleRange = null;
    if (customDateOpt) {
      newSaleRange = [
        {
          action: customDateOpt.action,
          startDate,
          endDate
        }
      ];
    }
    return {
      ageRange: selectedAgeRanges.some(x => x.value === 'todos')
        ? []
        : selectedAgeRanges
            .filter(x => x.value !== 'todos')
            .map(item => item.value),
      birthdayRange:
        selectedBirthdayRanges && selectedBirthdayRanges.length
          ? selectedBirthdayRanges[0].value
          : null,
      boughtRange:
        newSaleRange ||
        selectedSaleTimes.map(item => ({
          action: item.action,
          startDate: item.startDate,
          endDate: item.endDate
        })),
      sexo: selectedGenders.find(x => x.value === 'todos')
        ? []
        : selectedGenders
            .filter(x => x.value !== 'todos')
            .map(item => item.value),
      boughtGasStations: selectedEmpresas.some(x => x.value === 'todos')
        ? gasstation.map(item => item._id)
        : selectedEmpresas
            .filter(x => x.type === 'gasstation' && x.value !== 'todos')
            .map(item => item.value),
      boughtStores: selectedEmpresas.some(x => x.value === 'todos')
        ? store.map(item => item._id)
        : selectedEmpresas
            .filter(x => x.type === 'store' && x.value !== 'todos')
            .map(item => item.value),
      boughtProductgas: selectedProductgas.some(x => x.value === null)
        ? productgas.filter(x => x.active && x.value).map(item => item._id)
        : selectedProductgas.map(item => item.value)
    };
  }

  function clearFields() {
    setSelectedGenders([]);
    setSelectedAgeRanges([]);
    setSelectedBirthdayRages([]);
    setSelectedSaleTimes([]);
    setSelectedEmpresas([]);
    setSelectedProductgas([]);
    setStartDate(null);
    setEndDate(null);
  }
  useEffect(() => {
    switch (url) {
      case 'sms':
        setSendType('sms');
        break;
      case 'whatsapp':
        setSendType('whatsapp');
        break;
      case 'notificacoes':
        setSendType('push');
        break;
      default:
        setSendType(type);
    }
  }, []);

  useEffect(() => {
    if (data && data.clientsFilterPage && data.clientsFilterPage.docs) {
      setListDataTable(addNewItem(data.clientsFilterPage.docs));
      setTotalCount(data.clientsFilterPage.totalDocs);
    }
  }, [data]);
  useEffect(() => {
    if (called) {
      handleSearch();
    }
  }, [orderBy, direction, search, pageSize, page]);

  function handleSaleTimeChange(opt) {
    setSelectedSaleTimes(opt);
  }

  function handleSearch() {
    // setPage(0);
    setCalled(true);
    setAllClients([]);

    if (customFilter) {
      fetchMoreFilter();
    } else {
      fetchMore();
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          {!modalMode && (
            <>
              <h4>Seleção de Clientes</h4>
            </>
          )}
          <div className='subtitle'>
            <div className='firstSection'>
              Preencha os filtros desejados para envio de{' '}
              <strong>{sendType.toUpperCase()}</strong>:
            </div>
            {sendType === 'sms' ? (
              <div className='saldo'>
                <strong>Saldo: Restam {`${balance}`} SMS</strong>
              </div>
            ) : (
              <></>
            )}
            <br />
          </div>
          <Form>
            <Collapse isOpen>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      <i className='fas fa-venus-mars'></i> Por Sexo:
                    </Label>
                    <SelectGender
                      value={selectedGenders}
                      parentCallback={setSelectedGenders}
                      placeholder='Selecione o(s) sexo(s)...'
                      isMulti
                      selectAllOption
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      <i className='fas fa-users'></i> Por Faixa Etária:
                    </Label>
                    <SelectAgeRange
                      value={selectedAgeRanges}
                      parentCallback={setSelectedAgeRanges}
                      placeholder='Selecione a(s) faixa(s) etária(s)...'
                      isMulti
                      selectAllOption
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      <i className='fas fa-birthday-cake'></i> Por
                      Aniversariantes:
                    </Label>
                    <Select
                      options={BIRTHDAY_RANGE}
                      value={selectedBirthdayRanges}
                      onChange={opts => {
                        setSelectedBirthdayRages(
                          opts.length > 1 ? [opts[1]] : opts
                        );
                      }}
                      isMulti
                      placeholder='Selecione o período de aniversário...'
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <SelectEmpresa
                      label={
                        <span>
                          <i className='fas fa-building'></i> Compraram na(s)
                          Empresa(s): <Required>*</Required>
                        </span>
                      }
                      selectAllOption
                      parentCallback={setSelectedEmpresas}
                      value={selectedEmpresas}
                      placeholder='Selecione a(s) empresa(s)...'
                      ismulti
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      <i className='fas fa-dollar-sign'></i> Por Tempo de
                      Compra:
                    </Label>
                    <Select
                      options={SALE_TIME_RANGE}
                      value={selectedSaleTimes}
                      onChange={opt => handleSaleTimeChange(opt)}
                      closeMenuOnSelect={false}
                      placeholder='Selecione os período(s) de compra...'
                      isMulti
                      styles={{
                        backgroundColor: 'blue',
                        menu: provided => ({
                          ...provided,
                          zIndex: 9999
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <SelectProductGas
                      label={
                        <span>
                          <i className='fas fa-gas-pump'></i> Compraram o(s)
                          Combustível(eis): <Required>*</Required>
                        </span>
                      }
                      selectAllOption
                      parentCallback={setSelectedProductgas}
                      value={selectedProductgas}
                      placeholder='Selecione o(s) combustível(eis)...'
                      ismulti
                    />
                  </FormGroup>
                </Col>
              </Row>
              {selectedSaleTimes.find(x => x.value === 'DATA') && (
                <Row>
                  <Col>
                    <Label for='startDate'>Data Inicial </Label>
                    <Input
                      type='date'
                      id='startDate'
                      name='startDate'
                      onChange={e => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Label for='endDate'>Data Final</Label>
                    <Input
                      type='date'
                      id='endDate'
                      name='endDate'
                      onChange={e => setEndDate(e.target.value)}
                    />
                  </Col>
                  <Col lg={6}></Col>
                </Row>
              )}
              {selectedSaleTimes.find(x => x.value === 'DIA') && (
                <Row>
                  <Col lg={3}>
                    <Label for='days'>Quantidade de Dias</Label>
                    <InputMask
                      mask='99999'
                      onChange={e => {
                        const value = Number(
                          e.target.value.trim().replace(/_/g, '')
                        );
                        setStartDate(
                          dayjs()
                            .utc()
                            .subtract(value, 'days')
                            .format('YYYY-MM-DD')
                        );
                        setEndDate(null);
                        setDays(e.target.value);
                      }}
                      id='days'
                      name='days'
                      value={days}
                      className='form-control'
                      placeholder={''}
                    />
                  </Col>
                </Row>
              )}
              <p>
                <Required>*</Required> A seleção destes filtros excluem os
                Clientes que nunca compraram.
              </p>
            </Collapse>
            <Row>
              <Col md={6}>
                <Button
                  block
                  color='success'
                  outline
                  onClick={() => loadAllClients()}
                  disabled={loading}
                >
                  <i className='fas fa-users'></i> Selecionar todos os clientes
                  do filtro
                </Button>
                <Required>*</Required> A opção acima seleciona todos os clientes
                filtrados quando você selecionar os filtros e clicar em
                "Pesquisar Clientes".
              </Col>

              <Col>
                <Button block color='secondary' onClick={clearFields}>
                  <i className='fas fa-eraser'></i> Limpar Filtros
                </Button>
              </Col>

              <Col>
                <Button
                  block
                  color='primary'
                  disabled={loading}
                  onClick={() => {
                    handleSearch();
                    scrollToTable();
                  }}
                >
                  <i className='fas fa-search'></i> Pesquisar Clientes
                </Button>
              </Col>
              {modalMode ? (
                <Col>
                  <Button block color='success' onClick={confirm}>
                    Confirmar
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Link
                    to={{
                      pathname: `/marketing/${url}/selecao-de-clientes-V2/enviar`,
                      state: {
                        ...getFiltersState(),
                        sendType,
                        idClients:
                          selectedIDs.length > 0 ? selectedIDs : allClients,
                        totalClients:
                          selectedIDs.length ||
                          (data &&
                            data.clientsFilterPage &&
                            data.clientsFilterPage.totalDocs) ||
                          0
                      }
                    }}
                  >
                    <Button
                      block
                      color='success'
                      onClick={confirm}
                      disabled={
                        selectedIDs.length > 0 || allClients.length > 0
                          ? false
                          : true
                      }
                    >
                      Prosseguir <i className='fas fa-arrow-right'></i>
                    </Button>
                  </Link>
                </Col>
              )}
            </Row>
          </Form>
          <span id='table-clients'></span>
        </CardBody>
      </Card>
      <br />
      {called && (
        <Card>
          <CardBody>
            {allClients.length > 0 ? (
              <Alert>Todos os clientes foram selecionados</Alert>
            ) : (
              <MaterialTable
                sortableColumns={SORTABLE_COLUMNS}
                filterableColumns={FILTERABLE_COLUMNS}
                title='Clientes'
                data={listDataTable}
                columns={columnHeadClient}
                page={page}
                onChangePage={setPage}
                onChangeRowsPerPage={setPageSize}
                onSelectionChange={handleSelectionChange}
                onOrderChange={(order, dir) => {
                  setOrderBy(order);
                  setDirection(dir);
                }}
                onSearchChange={txt => {
                  setPage(0);
                  setSearch(txt);
                }}
                options={{
                  search: true,
                  selection: true
                }}
                isLoading={loading}
                totalCount={totalCount}
              />
            )}
            <Alert color='primary'>{totalCount} Clientes selecionados</Alert>
          </CardBody>
        </Card>
      )}
    </>
  );
};
const mapStateToProps = state => ({
  user: state.user,
  gasstation: state.gasstation,
  store: state.store,
  productgas: state.productgas
});
export default connect(mapStateToProps)(ClientsSelection);
