/* eslint-disable no-unused-vars */
import { request as graphql } from 'graphql-request';
import {
  addQueryPromotion,
  listQueryPromotion,
  removeQueryPromotions,
  getPromotionsId,
  updateQueryPromotion
} from '../queries/promotions';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_PROMOTION: 'promotion/SET',
  SET_ERROR: 'promotion/ERROR',
  SET_SUCCESS: 'promotion/SUCCESS'
};

const INITIAL_STATE = [];

export default function promotions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PROMOTION:
      return action.promotions;
    case Types.SET_ERROR:
      return action.error;
    case Types.SET_SUCCESS:
      return action.success;
    default:
      return state;
  }
}

const setPromotions = promotions => ({
  type: Types.SET_PROMOTION,
  promotions
});

const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setPromotions,
  setError,
  setSuccess,
  addPromotion: (
    name,
    gasstation,
    customer,
    productgas,
    liters,
    litersMax,
    use,
    useByClient,
    dateinitial,
    dateend,
    payments,
    user,
    active,
    type,
    discount,
    featured,
    url,
    minimumValue,
    limitedForFirstPurchase
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        addQueryPromotion(
          name,
          gasstation,
          customer,
          productgas,
          liters,
          litersMax,
          use,
          useByClient,
          dateinitial,
          dateend,
          payments,
          user,
          active,
          type,
          discount,
          featured,
          url,
          minimumValue,
          limitedForFirstPurchase
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { _id, name, error } = data.addPromotion;
          if (error) {
            dispatch(setError({ error: error }));
          } else if (_id) {
            dispatch(
              setSuccess({ success: 'Promoção cadastrada com sucesso!' })
            );
          } else {
            dispatch(
              setError({ error: 'Houve um erro ao cadastrar a promoção' })
            );
          }
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve um erro ao cadastrar a promoção' })
          );
        });
    };
  },
  getListPromotions: customer => {
    return dispatch => {
      graphql(
        config.URL_API,
        listQueryPromotion(customer),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          dispatch(setPromotions(data.promotionAllCustomer));
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao listar as promoçòes' }))
        );
    };
  },
  deletePromotions: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        removeQueryPromotions(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.removeProduct;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao deletar a promoção' }));
        });
    };
  },
  updatePromotions: (
    id,
    name,
    gasstation,
    customer,
    productgas,
    liters,
    litersMax,
    use,
    useByClient,
    dateinitial,
    dateend,
    payments,
    user,
    active,
    type,
    discount,
    featured,
    url,
    minimumValue,
    limitedForFirstPurchase
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        updateQueryPromotion(
          id,
          name,
          gasstation,
          customer,
          productgas,
          liters,
          litersMax,
          use,
          useByClient,
          dateinitial,
          dateend,
          payments,
          user,
          active,
          type,
          discount,
          featured,
          url,
          minimumValue,
          limitedForFirstPurchase
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updatePromotion;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve um erro ao atualizar a promoção' })
          );
        });
    };
  },
  getPromotionsId: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getPromotionsId(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { _id } = data.promotion;

          if (_id) {
            dispatch(setPromotions(data.promotion));
          } else {
            dispatch(setError({ error: 'Houve um erro ao listar a promoção' }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao cadastrar a promoção' }))
        );
    };
  }
};
