import { gql } from '@apollo/client';

export const getQueryListNotification = id =>
  `{
          notifications(customer : "${id}"){
              title text dateSend deviceenviados user {
                  _id name
              }
            }
      }`;

export const addQueryNotification = (
  title,
  text,
  dataSend,
  active = true,
  user,
  customer
) => `
  mutation{
    addNotification(
        title: "${title}"
        text: "${text}"
        dateSend: "${dataSend}"
        status: ${active}
        user: "${user}"
        customer: "${customer}"
    ){
      _id title text
    }
  }
  `;

export const ADD_NOTIFICATION = gql`
  mutation addNotification(
    $title: String
    $text: String
    $dateSend: Date
    $status: Boolean
    $user: ID
    $customer: ID
  ) {
    addNotification(
      title: $title
      text: $text
      dateSend: $dateSend
      status: $status
      user: $user
      customer: $customer
    ) {
      success
      message
    }
  }
`;
