import { request as graphql } from 'graphql-request';
import {
  addQueryPrice,
  getQueryPrice,
  getQueryPriceCustomer,
  updateQueryPrice,
  deleteQueryPrice
} from '../queries/price';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_PRICE: 'price/SET',
  SET_ERROR: 'price/ERROR',
  SET_SUCCESS: 'price/SUCCESS'
};

const INITIAL_STATE = [];

export default function price(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PRICE:
      return action.price;
    case Types.SET_SUCCESS:
      return action.success;
    case Types.SET_ERROR:
      return action.error;
    default:
      return state;
  }
}

const setPrice = price => ({
  type: Types.SET_PRICE,
  price
});
const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setPrice,
  setError,
  setSuccess,
  getPrice: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryPrice(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { price } = data;

          dispatch(setPrice(price));
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao buscar os preços' }));
        });
    };
  },
  addPrice: (
    value,
    discount,
    gasstation,
    product,
    type,
    priceWithDiscount,
    customer,
    priceDeb,
    discountDeb,
    priceCar,
    discountCar,
    priceCreWithDiscout,
    priceDebWithDiscount,

    //REF 1
    priceCust1,
    discountCust1,
    priceWithDiscountCust1,

    //REF 2
    priceCust2,
    discountCust2,
    priceWithDiscountCust2,

    //REF 3
    priceCust3,
    discountCust3,
    priceWithDiscountCust3
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        addQueryPrice(
          value,
          discount,
          gasstation,
          product,
          type,
          priceWithDiscount,
          customer,
          priceDeb,
          discountDeb,
          priceCar,
          discountCar,
          priceCreWithDiscout,
          priceDebWithDiscount,

          //REF 1
          priceCust1,
          discountCust1,
          priceWithDiscountCust1,

          //REF 2
          priceCust2,
          discountCust2,
          priceWithDiscountCust2,

          //REF 3
          priceCust3,
          discountCust3,
          priceWithDiscountCust3
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { _id } = data.addPrice;

          if (_id) {
            dispatch(setSuccess({ success: 'Preço cadastrado com sucesso!' }));
          } else {
            dispatch(setError({ error: 'Houve um erro ao adicionar o preço' }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao adicionar o preço' }));
        });
    };
  },
  getPriceCustomer: customer => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryPriceCustomer(customer),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { pricesCustomer } = data;

          if (pricesCustomer) {
            dispatch(setPrice(pricesCustomer));
          } else {
            dispatch(setError({ error: 'Houve um erro ao buscar o preço' }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao buscar o preço' }))
        );
    };
  },
  updatePrice: (
    id,
    value,
    discount,
    gasstation,
    product,
    type,
    priceWithDiscount,
    customer,
    priceDeb,
    discountDeb,
    priceCar,
    discountCar,
    priceCreWithDiscount,
    priceDebWithDiscount,

    //REF 1
    priceCust1,
    discountCust1,
    priceWithDiscountCust1,

    //REF 2
    priceCust2,
    discountCust2,
    priceWithDiscountCust2,

    //REF 3
    priceCust3,
    discountCust3,
    priceWithDiscountCust3,
    userUpdate
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        updateQueryPrice(
          id,
          value,
          discount,
          gasstation,
          product,
          type,
          priceWithDiscount,
          customer,
          priceDeb,
          discountDeb,
          priceCar,
          discountCar,
          priceCreWithDiscount,
          priceDebWithDiscount,

          //REF 1
          priceCust1,
          discountCust1,
          priceWithDiscountCust1,

          //REF 2
          priceCust2,
          discountCust2,
          priceWithDiscountCust2,

          //REF 3
          priceCust3,
          discountCust3,
          priceWithDiscountCust3,
          userUpdate
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updatePrice;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao atualizar o preço' }))
        );
    };
  },
  deletePrice: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        deleteQueryPrice(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.removePrice;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao excluir o preço' }))
        );
    };
  }
};
