import React, { useState, createContext, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { Button } from '../../components/Form/Button';

import {
  IdLabel,
  CreatedAtLabel,
  UpdatedAtLabel,
  UserLabel,
  UserUpdateLabel,
  ActiveLabel
} from './InfoLabels';

export const AlertModalContext = createContext({});

let callbackConfirm = null;

export const AlertModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const [contentModal, setContentModal] = useState('');
  const [titleModal, setTitleModal] = useState('Informações');
  const [itemsList, setItemsList] = useState({});
  const [buttonsList, setButtonsList] = useState([]);
  const [modalSize, setModalSize] = useState('lg');

  function renderItem(label, value) {
    switch (label) {
      case '__id__':
        return (
          <li>
            <IdLabel>{value}</IdLabel>
          </li>
        );
      case '__createdAt__':
        return (
          <li>
            <CreatedAtLabel>{value}</CreatedAtLabel>
          </li>
        );
      case '__updatedAt__':
        return (
          <li>
            <UpdatedAtLabel>{value}</UpdatedAtLabel>
          </li>
        );
      case '__user__':
        return (
          <li>
            <UserLabel>{value}</UserLabel>
          </li>
        );
      case '__userUpdate__':
        return (
          <li>
            <UserUpdateLabel>{value}</UserUpdateLabel>
          </li>
        );
      case '__active__':
        return (
          <li>
            <ActiveLabel>{value}</ActiveLabel>
          </li>
        );
      default:
        return value && typeof value === 'object' ? (
          <>
            {label && <li>{label}:</li>}
            <ul>
              {Object.keys(value).map(key => renderItem(key, value[key]))}
            </ul>
          </>
        ) : (
          <li>
            {label}: <b>{value}</b>
          </li>
        );
    }
  }

  function mountModalContent(content, title, buttons, items, size) {
    setTitleModal(title);
    setContentModal(content);
    setButtonsList(buttons);
    setItemsList(items);
    setModalSize(size);
  }

  function showModal({
    content,
    title = 'Informações',
    buttons,
    itemsList,
    modalSize
  }) {
    console.info('aq', itemsList);
    mountModalContent(content, title, buttons, itemsList, modalSize);
    openModal();
  }

  function handleConfirm(btn) {
    closeModal();

    callbackConfirm = btn.onClick;
    if (callbackConfirm) {
      callbackConfirm();
    }
  }

  return (
    <AlertModalContext.Provider
      value={{
        isOpen,
        showModal,
        closeModal
      }}
    >
      <Modal
        id='modal-content'
        isOpen={isOpen}
        toggle={closeModal}
        size={modalSize}
      >
        <ModalHeader toggle={closeModal} charCode='X'>
          {titleModal}
        </ModalHeader>
        <ModalBody>
          {contentModal}
          {itemsList && Object.keys(itemsList).length > 0 && (
            <ul>
              {Object.keys(itemsList).map(key =>
                renderItem(key, itemsList[key])
              )}
            </ul>
          )}
        </ModalBody>
        {buttonsList && buttonsList.length && (
          <ModalFooter>
            {buttonsList.map(btn => (
              <Button
                key={btn.label}
                style={{ width: 120 }}
                color={btn.color}
                onClick={() => handleConfirm(btn)}
                startIcon={btn.icon}
              >
                {btn.label}
              </Button>
            ))}
          </ModalFooter>
        )}
      </Modal>
      {children}
    </AlertModalContext.Provider>
  );
};
