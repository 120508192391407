import { gql } from '@apollo/client';

export const getCustomers = () =>
  `{
        customers{
            _id name email phone site whatsapp createdAt updatedAt timezone confiPushNotifaction processManual
          }
    }`;

export const getQueryCustomersId = id =>
  `{
        customer(_id : "${id}"){
            _id name email phone site whatsapp timezone confiPushNotifaction processManual
          }
    }`;

export const deleteQueryCustomer = id => `
mutation{
	removeCustomer(_id : "${id}") {
    success message

  }
}

`;

export const updateQueryCustomer = (
  id,
  name,
  email,
  active,
  phone,
  primaryLogo,
  secondaryLogo,
  site,
  whatsapp,
  timezone
) => `
  mutation {
    updateCustomer(
      _id: "${id}"
      name: "${name}"
      email: "${email}"
      active: ${active}
      phone: "${phone}"
      primaryLogo: "${primaryLogo}"
      secondaryLogo: "${secondaryLogo}"
      site: "${site}"
      whatsapp: "${whatsapp}"
      timezone: ${timezone}
      ){success message}
  }

`;

export const setQueryCustomers = (
  name,
  email,
  active,
  phone,
  primaryLogo,
  secondaryLogo,
  site,
  whatsapp,
  timezone
) => `
  mutation {
    addCustomer(
      name: "${name}"
      email: "${email}"
      active: ${active}
      phone: "${phone}"
      primaryLogo: "${primaryLogo}"
      secondaryLogo: "${secondaryLogo}"
      site: "${site}"
      whatsapp: "${whatsapp}"
      timezone: ${timezone}
      ){
        name email active phone primaryLogo secondaryLogo site whatsapp timezone
      }
  }

`;

export const GET_CUSTOMER_REGISTRATION_DATE = gql`
  query getRegistrationDate($id: ID!) {
    customer(_id: $id) {
      createdAt
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($_id: ID!) {
    customer(_id: $_id) {
      _id
      name
      email
      phone
      site
      whatsapp
      confiPushNotifaction
      timezone
      processManual
      distributor {
        name
        _id
      }
    }
  }
`;
export const GET_CUSTOMERS = gql`
  query getCustomers {
    customers {
      _id
      name
      email
      phone
      site
      whatsapp
      createdAt
      updatedAt
      timezone
      active
      processManual
      marketplaceCustomers {
        _id
      }
      belongsToMarketPlaces {
        _id
      }
      confiPushNotifaction
      distributor {
        name
        _id
      }
    }
  }
`;
export const ADD_CUSTOMER = gql`
  mutation addCustomer($newCustomer: CustomerCreationInput) {
    addCustomer(newCustomer: $newCustomer) {
      _id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($_id: ID!, $updateCustomer: CustomerUpdateInput) {
    updateCustomer(_id: $_id, updateCustomer: $updateCustomer) {
      success
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation removeCustomer($_id: ID) {
    removeCustomer(_id: $_id) {
      success
      message
    }
  }
`;

export const TOGGLE_CUSTOMER = gql`
  mutation ToggleCustomer($customerId: ID!) {
    toggleCustomer(customerId: $customerId) {
      data
      message
      stackTrace
      success
    }
  }
`;
