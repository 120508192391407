import { gql } from '@apollo/client';

export const ADD_COMTELE_ACCOUNT = gql`
  mutation addComteleAccount($input: AddComteleAccountInput) {
    addComteleAccount(input: $input) {
      success
      message
    }
  }
`;

export const GET_COMTELE_ACCOUNT = gql`
  query getComteleAccount($customer: ID!) {
    comteleAccountCustomer(customer: $customer) {
      firstName
      lastName
      email
      cpf
      cnpj
      phone
      enabled
      userName
      ApiKey
      createdAt
      updatedAt
      customer {
        _id
        name
      }
    }
  }
`;

export const GET_COMTELE_ACCOUNT_BALANCE = gql`
  query comteleAccountBalance($customer: ID!) {
    getComteleAccountBalance(customer: $customer) {
      balance
    }
  }
`;
