import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

import { ApolloProvider } from '@apollo/client';

import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import client from './config/apollo';
import { ThemeProvider } from 'styled-components';
import { styledTheme } from './assets/theme';

ReactDOM.render(
  <ThemeProvider theme={styledTheme}>
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </ApolloProvider>
    ,
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
