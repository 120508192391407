import { gql } from '@apollo/client';

const inputFields = `
$name: String
$type: String
$ref: String
$guid: String
$paymentIcon: String
$refPrice: String
$customer: ID
$authorizesCashback: Boolean`;

const variables = `
name: $name
type: $type
ref: $ref
guid: $guid
refPrice: $refPrice
paymentIcon: $paymentIcon
customer: $customer
authorizesCashback: $authorizesCashback`;

export const getQueryPayments = customer => `
{
    payments (customer: "${customer}"){
        _id name refPrice type active guid ref paymentIcon createdAd updatedAt authorizesCashback customer { _id name }
    }
}
`;

export const ACTIVATE_PAYMENTS = gql`
  mutation activePayment($_id: ID) {
    activePayments(_id: $_id) {
      success
      message
    }
  }
`;

export const INACTIVATE_PAYMENTS = gql`
  mutation inactivatePayment($_id: ID) {
    removePayments(_id: $_id) {
      success
      message
    }
  }
`;

export const UPDATE_PAYMENTS = gql`
  mutation updatePayment($_id: ID, ${inputFields}) {
    updatePayments(_id: $_id, ${variables}) {
      success
      message
    }
  }
`;

export const GET_CUSTOM_PAYMENTS = gql`
  query getCustomPayments(
    $customer: ID!
    $authorizesCashback: Boolean
    $active: Boolean
  ) {
    customPayments(
      customer: $customer
      authorizesCashback: $authorizesCashback
      active: $active
    ) {
      _id
      name
      type
      active
      guid
      ref
      refPrice
      paymentIcon
      createdAd
      updatedAt
      authorizesCashback
      customer {
        _id
        name
      }
    }
  }
`;

export const GET_AVAILABLE_PAYMENTS = gql`
  query getAvailablePayments($customer: ID!) {
    paymentsAvailableCustomer(customer: $customer) {
      _id
      name
      type
      active
      ref
      guid
      refPrice
      paymentIcon
      createdAd
      updatedAt
      authorizesCashback
      weight
      position
      customer {
        _id
        name
      }
    }
  }
`;

export const SAVE_CUSTOM_PAYMENTS = gql`
  mutation saveCustomPayments($objects: [PaymentsInput]) {
    savePayments(objects: $objects) {
      success
      message
    }
  }
`;

export const ADD_PAYMENTS = gql`
  mutation addPayments(
    $name: String
    $type: String
    $ref: String
    $refPrice: String
    $paymentIcon: String
    $authorizesCashback: Boolean
    $customer: ID
    $weight: Int
    $position: Int
    $guid: String
  ) {
    addPayments(
      name: $name
      type: $type
      ref: $ref
      refPrice: $refPrice
      paymentIcon: $paymentIcon
      authorizesCashback: $authorizesCashback
      customer: $customer
      weight: $weight
      position: $position
      guid: $guid
    ) {
      _id
      success
      message
    }
  }
`;

export const GET_PAYMENTS = gql`
  query getPaymentsCustomer($customer: ID) {
    payments(customer: $customer) {
      name
      _id
      type
      active
      paymentIcon
      ref
      guid
      refPrice
      createdAd
      updatedAt
      authorizesCashback
      weight
      position
      customer {
        _id
        name
      }
    }
  }
`;

export const GET_PAYMENT = gql`
  query getPayment($_id: ID!) {
    payment(_id: $_id) {
      _id
      name
      type
      active
      refPrice
      guid
      paymentIcon
      ref
      authorizesCashback
      weight
      position
      paymentWeight {
        position
        weight
      }
      customer {
        _id
        name
      }
    }
  }
`;
