import { gql } from '@apollo/client';

export const getQueryStore = (customer, active) =>
  `
  {
    storesCustomer(
      customer: "${customer}",
      active: true,
    ) {
    docs {  _id name cnpj address N phone lat lng createdAt updatedAt category { _id name } timezone }
    }
  }
  `;

export const getQueryId = id => `
      {
        store(_id: "${id}"){
          _id name cnpj
          razaosocial
          city
          cep
          uf
          address
          phone
          N
          lat
          lng
          image
          bio
          images
          createdAt
          facebook
          instagram
          site
          whatsapp
          timezone
          category {
            _id name
          }
          neighborhood
          hiddenApp
        }
      }
  `;

export const GET_LIST_STORE = gql`
  query getListStoreCustomer(
    $customer: ID!
    $offset: Int
    $limit: Int
    $active: Boolean
  ) {
    storesCustomer(
      customer: $customer
      offset: $offset
      limit: $limit
      active: $active
    ) {
      docs {
        _id
        name
        cnpj
        address
        N
        active
        createdAt
        updatedAt
        category {
          _id
          name
        }
      }
      page
      totalDoc
    }
  }
`;

export const setQueryStore = (
  name,
  cnpj,
  razaosocial,
  city,
  cep,
  uf,
  address,
  phone,
  numero,
  lat,
  lng,
  image,
  neighborhood,
  customer,
  category,
  urlOther,
  whatsapp,
  bio,
  facebook,
  instagram,
  site,
  hiddenApp
) => `
  mutation{
      addStore(
        name: "${name}"
        cnpj: "${cnpj}"
        razaosocial: "${razaosocial}"
        city: "${city}"
        cep: "${cep}"
        uf: "${uf}"
        address: "${address}"
        phone: "${phone}"
        N: "${numero}"
        lat: "${lat}"
        lng: "${lng}"
        image: "${image}"
        customer: "${customer}"
        category: [${category.map(item => '"' + item.value + '"')}]
        neighborhood: "${neighborhood}"
        hiddenApp: "${hiddenApp}"
        images:${
          urlOther ? '[' + urlOther.map(item => '"' + item + '"') + ']' : null
        }
        whatsapp: "${whatsapp}"
         bio : "${bio}"
         facebook: "${facebook}"
         instagram: "${instagram}"
         site: "${site}"
      ){
          _id name cnpj error timezone
      }
  }`;

export const deletQueryStore = id => `
mutation{
	removeStore(
        _id: "${id}"
        ){
    success message

  }
}
`;

export const updateQuery = (
  id,
  name,
  cnpj,
  razaosocial,
  city,
  cep,
  uf,
  address,
  phone,
  numero,
  lat,
  lng,
  image,
  neighborhood,
  customer,
  category,
  urlOther,
  whatsapp,
  bio,
  facebook,
  instagram,
  site,
  hiddenApp
) => `
mutation{
  updateStore(
      _id: "${id}",
      name: "${name}"
      cnpj: "${cnpj}"
      razaosocial: "${razaosocial}"
      city: "${city}"
      cep: "${cep}"
      uf: "${uf}"
      address: "${address}"
      phone: "${phone}"
      N: "${numero}"
      lat: "${lat}"
      lng: "${lng}"
      image: "${image}"
      customer: "${customer}"
      category: [${category.map(item => '"' + item.value + '"')}]
      neighborhood: "${neighborhood}"
      hiddenApp: "${hiddenApp}"
      images:${
        urlOther ? '[' + urlOther.map(item => '"' + item + '"') + ']' : null
      }
      whatsapp: "${whatsapp}"
      bio: "${bio}"
      facebook: "${facebook}"
      instagram: "${instagram}"
      site: "${site}"
    ){
       success message
    }
}`;

export const NEW_GET_STORE = gql`
  query StoresPagination(
    $customer: ID
    $offset: Int
    $limit: Int
    $active: Boolean
    $search: String
  ) {
    storesPagination(
      customer: $customer
      offset: $offset
      limit: $limit
      active: $active
      search: $search
    ) {
      docs {
        _id
        name
        cnpj
        address
        N
        active
        createdAt
        updatedAt

        category {
          _id
          name
          __typename
        }
        __typename
      }
      page
      totalDoc
    }
  }
`;

export const GET_STORE = gql`
  query getStore($_id: ID!) {
    store(_id: $_id) {
      _id
      name
      cnpj
      razaosocial
      city
      cep
      uf
      address
      phone
      N
      lat
      lng
      image
      bio
      images
      createdAt
      facebook
      instagram
      timezone
      site
      whatsapp
      hiddenApp
      category {
        _id
        name
      }
      neighborhood
    }
  }
`;

export const ACTIVATE_STORE = gql`
  mutation ActiveStore($id: ID) {
    activeStore(_id: $id) {
      data
      message
      stackTrace
      success
    }
  }
`;
export const REMOVE_STORE = gql`
  mutation RemoveStore($id: ID) {
    removeStore(_id: $id) {
      data
      message
      stackTrace
      success
    }
  }
`;

export const ADD_STORE = gql`
  mutation addStore(
    $name: String
    $cnpj: String
    $razaosocial: String
    $city: String
    $cep: String
    $uf: String
    $address: String
    $phone: String
    $whatsapp: String
    $N: String
    $lat: String
    $lng: String
    $image: String
    $createdAt: Date
    $customer: ID
    $category: [ID]
    $neighborhood: String
    $images: [String]
    $bio: String
    $facebook: String
    $instagram: String
    $site: String
    $timezone: Int
    $hiddenApp: Boolean
  ) {
    addStore(
      name: $name
      cnpj: $cnpj
      razaosocial: $razaosocial
      city: $city
      cep: $cep
      uf: $uf
      address: $address
      phone: $phone
      whatsapp: $whatsapp
      N: $N
      lat: $lat
      lng: $lng
      image: $image
      createdAt: $createdAt
      customer: $customer
      category: $category
      neighborhood: $neighborhood
      images: $images
      bio: $bio
      facebook: $facebook
      instagram: $instagram
      site: $site
      timezone: $timezone
      hiddenApp: $hiddenApp
    ) {
      _id
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation updateStore(
    $_id: ID
    $name: String
    $cnpj: String
    $razaosocial: String
    $city: String
    $cep: String
    $uf: String
    $address: String
    $phone: String
    $whatsapp: String
    $N: String
    $lat: String
    $lng: String
    $image: String
    $customer: ID
    $category: [ID]
    $neighborhood: String
    $images: [String]
    $bio: String
    $facebook: String
    $instagram: String
    $site: String
    $timezone: Int
    $hiddenApp: Boolean
  ) {
    updateStore(
      _id: $_id
      name: $name
      cnpj: $cnpj
      razaosocial: $razaosocial
      city: $city
      cep: $cep
      uf: $uf
      address: $address
      phone: $phone
      whatsapp: $whatsapp
      N: $N
      lat: $lat
      lng: $lng
      image: $image
      customer: $customer
      category: $category
      neighborhood: $neighborhood
      images: $images
      bio: $bio
      facebook: $facebook
      instagram: $instagram
      site: $site
      timezone: $timezone
      hiddenApp: $hiddenApp
    ) {
      success
      message
    }
  }
`;
