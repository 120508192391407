export const addQueryProduct = (
  name,
  image,
  store,
  categories,
  images,
  ref,
  price,
  priceDes,
  description,
  customer,
  unit,
  destaque
) => `
mutation{
    addProduct(
        name: "${name}"
        image: "${image}"
        store: "${store}"
        categories: [${
          categories.length > 0
            ? categories.map(item => '"' + item.value + '"')
            : null
        }]
        images:${
          images ? '[' + images.map(item => '"' + item + '"') + ']' : null
        }
        ref:"${ref}"
        customer: "${customer}"
        price: "${price}"
        priceDes: "${priceDes}"
        description: "${description}"
        unit: "${unit}"
        destaque: ${destaque}
    ){
        _id name
    }
}
`;

export const getQueryProductStore = id => `
{
    productsStore(store: "${id}"){
        _id name  image unit ref price priceDes destaque categories{
            _id name
        }
    }
}
`;

export const getQueryListProduct = customer => `
{
    productsCustomer(customer:"${customer}"){
        _id name image unit ref price priceDes destaque createdAt updatedAt 
        categories { _id name }
        store { _id name }
        gasstation { _id name }
    }
}`;

export const deleteQueryProduct = id => `
mutation {
    removeProduct(_id: "${id}"){
        success message
    }
}
`;

export const getQueryProducts = id => `
{
    product(_id: "${id}"){
        _id name image images unit  description price priceDes ref destaque categories{
            _id name
          }
    }

}`;

export const updateQueryProduct = (
  id,
  name,
  image,
  store,
  categories,
  images,
  ref,
  price,
  priceDes,
  description,
  unit,
  destaque
) => `
mutation{
    updateProduct(
        _id:"${id}",
        name: "${name}"
        image: "${image}"
        store: "${store}"
        categories: [${
          categories.length > 0
            ? categories.map(item => '"' + item.value + '"')
            : null
        }]
        images:${
          images ? '[' + images.map(item => '"' + item + '"') + ']' : null
        }
        ref:"${ref}"
        price: "${price}"
        priceDes: "${priceDes}"
        description: "${description}"
        unit: "${unit}"
        destaque: ${destaque}
    ){
       success message
    }
}
`;
