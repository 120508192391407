import { gql } from '@apollo/client';

export const addQueryPromotion = (
  name,
  gasstation,
  customer,
  productgas,
  liters,
  litersMax,
  use,
  useByClient,
  dateinitial,
  dateend,
  payments,
  user,
  active,
  type,
  discount,
  featured,
  url,
  minimumValue,
  limitedForFirstPurchase
) => `
mutation{
    addPromotion(name: "${name}"
    gasstation:"${gasstation}"
    customer: "${customer}"
    productgas: "${productgas}"
    liters: ${liters}
    litersMax: ${litersMax}
    use: ${use}
    useByClient: ${useByClient}
    dateinitial: "${dateinitial}"
    dateend: "${dateend}"
    payments:"${payments}"
    user: "${user}"
    active: ${true}
    type: "${type}"
    discount: "${discount}"
    featured: ${featured}
    image: "${url}"
    minimumValue: "${minimumValue}"
    limitedForFirstPurchase: ${limitedForFirstPurchase}

    ){
        _id name error
    }
}
`;

export const listQueryPromotion = customer => `
{
  promotionAllCustomer(customer:"${customer}"){
    _id name discount type liters litersMax active minimumValue dateinitial dateend use useByClient limitedForFirstPurchase
    used
    concludedVouchers
    notConcludedVouchers
    canceledVouchers
    expiredVouchers
    availableUse
    createdAd updatedAt
    payments{
      _id name
    }
    gasstation{
      _id name
    }
    productgas {
      _id name
    }
    user {
      _id name
    }
    userUpdate {
      _id name
    }
  }
}`;

export const updateQueryPromotion = (
  id,
  name,
  gasstation,
  customer,
  productgas,
  liters,
  litersMax,
  use,
  useByClient,
  dateinitial,
  dateend,
  payments,
  userUpdate,
  active,
  type,
  discount,
  featured,
  url,
  minimumValue,
  limitedForFirstPurchase
) => `
mutation{
  updatePromotion(
    _id: "${id}"
    name: "${name}"
    gasstation:"${gasstation}"
    customer: "${customer}"
    productgas: "${productgas}"
    liters: ${liters}
    litersMax: ${litersMax}
    use: ${use}
    useByClient: ${useByClient}
    dateinitial: "${dateinitial}"
    dateend: "${dateend}"
    payments:"${payments}"
    userUpdate: "${userUpdate}"
    active: ${active}
    type: "${type}"
    discount: "${discount}"
    featured: ${featured}
    image: "${url}"
    minimumValue: "${minimumValue}"
    limitedForFirstPurchase: ${limitedForFirstPurchase}
    ){
       success message
    }
}
`;

export const GET_PROMOTION = gql`
  query getPromotion($id: ID!) {
    promotion(_id: $id) {
      _id
      name
      gasstation {
        _id
        name
        timezone
      }
      store {
        _id
        name
      }
      category {
        _id
        name
      }
      image
      productgas {
        _id
        name
      }
      productgasGroup {
        _id
        name
      }
      min
      max
      typeFormat
      use
      used
      useByClient
      concludedVouchers
      notConcludedVouchers
      canceledVouchers
      expiredVouchers
      availableUse
      dateinitial
      dateend
      limitedForFirstPurchase
      type
      discount
      featured
      minimumValue
      typeItem
      category {
        _id
        name
      }
      image
      productgas {
        _id
        name
      }
      min
      max
      typeFormat
      use
      used
      useByClient
      concludedVouchers
      notConcludedVouchers
      canceledVouchers
      expiredVouchers
      availableUse
      dateinitial
      dateend
      limitedForFirstPurchase
      payments {
        _id
        name
      }
      type
      discount
      featured
      minimumValue
      typeItem
    }
  }
`;

export const ADD_PROMOTION = gql`
  mutation addPromotion(
    $name: String
    $gasstation: ID
    $customer: ID
    $productgas: ID
    $productgasGroup: ID
    $liters: Int
    $litersMax: Int
    $use: Int
    $useByClient: Int
    $dateinitial: Date
    $dateend: Date
    $payments: ID
    $user: ID
    $active: Boolean
    $type: String
    $discount: String
    $featured: Boolean
    $minimumValue: String
    $limitedForFirstPurchase: Boolean
    $image: String
    $typeItem: TypeItem
  ) {
    addPromotion(
      name: $name
      gasstation: $gasstation
      customer: $customer
      productgas: $productgas
      productgasGroup: $productgasGroup
      liters: $liters
      litersMax: $litersMax
      use: $use
      useByClient: $useByClient
      dateinitial: $dateinitial
      dateend: $dateend
      payments: $payments
      user: $user
      active: $active
      type: $type
      discount: $discount
      featured: $featured
      minimumValue: $minimumValue
      limitedForFirstPurchase: $limitedForFirstPurchase
      image: $image
      typeItem: $typeItem
    ) {
      _id
      name
      error
    }
  }
`;

// Quando for usar o novo cadastro de promoção
// export const ADD_PROMOTION = gql`
//   mutation addPromotion(
//     $name: String
//     $sendNotification: Boolean
//     $initialDate: Date
//     $finalDate: Date
//     $limitedForFirstPurchase: Boolean
//     $active: Boolean = true
//     $featured: Boolean = false
//     $use: Int
//     $useByClient: Int
//     $image: String
//     $typeItem: TypeItem #["PRODUTOS", "SERVIÇOS", "COMBUSTÍVEL"]
//     $boughtRange: String
//     $customer: ID
//     $createdBy: String
//     $updatedBy: String
//   ) {
//     addPromotion(
//       name: $name
//       sendNotification: $sendNotification
//       initialDate: $initialDate
//       finalDate: $finalDate
//       limitedForFirstPurchase: $limitedForFirstPurchase
//       active: $active
//       featured: $featured
//       use: $use
//       useByClient: $useByClient
//       image: $image
//       typeItem: $typeItem
//       boughtRange: $boughtRange
//       customer: $customer
//       createdBy: $createdBy
//       updatedBy: $updatedBy
//     ) {
//       _id
//       name
//       error
//     }
//   }
// `;

export const ADD_MANY_PROMOTIONS = gql`
  mutation addManyPromotion($objects: [PromotionInput]) {
    addManyPromotions(objects: $objects) {
      success
      message
    }
  }
`;

export const GET_PROMOTION_NAME = gql`
  query getPromotionName($customer: ID!) {
    promotionsName(customer: $customer)
  }
`;

export const GET_PROMOTIONS_CUSTOMER = gql`
  query getPromotionsCustomer($customer: ID) {
    promotionCustomer(customer: $customer) {
      _id
      name
      discount
      type
      liters
      litersMax
      active
      use
      useByClient
      minimumValue
      dateinitial
      dateend
      used
      concludedVouchers
      notConcludedVouchers
      canceledVouchers
      expiredVouchers
      availableUse
      limitedForFirstPurchase
      payments {
        _id
        name
      }
      gasstation {
        _id
        name
        timezone
      }
      store {
        _id
        name
      }
      category {
        _id
        name
      }
      productgas {
        _id
        name
      }
      typeItem
    }
  }
`;

export const GET_ALL_PROMOTIONS_CUSTOMER = gql`
  query getAllPromotionsCustomer(
    $customer: ID!
    $active: Boolean
    $situation: String
    $use: Boolean
    $gasstation: [ID]
    $store: [ID]
    $typeItem: [String]
    $productgas: [ID]
    $category: [ID]
    $payments: [ID]
    $litersMin: Float
    $valueMin: Float
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
    $name: [String]
  ) {
    promotionAllCustomer(
      customer: $customer
      active: $active
      situation: $situation
      use: $use
      gasstation: $gasstation
      store: $store
      typeItem: $typeItem
      productgas: $productgas
      category: $category
      payments: $payments
      litersMin: $litersMin
      valueMin: $valueMin
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields
      name: $name
    ) {
      totalDocs
      page
      totalPages
      docs {
        _id
        name
        discount
        type
        typeFormat
        min
        max
        active
        use
        useByClient
        minimumValue
        dateinitial
        dateend
        used
        concludedVouchers
        notConcludedVouchers
        canceledVouchers
        expiredVouchers
        availableUse
        limitedForFirstPurchase
        payments {
          _id
          name
        }
        gasstation {
          _id
          name
          timezone
        }
        store {
          _id
          name
        }
        category {
          _id
          name
        }
        productgas {
          _id
          name
          unit {
            symbol
            name
          }
        }
        productgasGroup {
          _id
          name
        }
        createdAd
        updatedAt
        active
        user {
          _id
          name
        }
        userUpdate {
          _id
          name
        }
        typeItem
        isExpired
        isFuture
      }
    }
  }
`;

export const CUSTOMER_PROMOTIONS = gql`
  query customerPromotions(
    $customer: ID!
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
  ) {
    customerPromotions(
      customer: $customer
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields
    ) {
      totalDocs
      page
      totalPages
      docs {
        _id
        promotionName
        gasStation
        productGas
        discount
        promotionOption
        minValue
        promotionType
        paymentType
        limitUse
        currentUsage
        active
        customer {
          name
        }
      }
    }
  }
`;

export const REMOVE_PROMOTIONS = gql`
  mutation removePromotions($id: ID) {
    removePromotion(_id: $id) {
      message
      success
    }
  }
`;

export const ACTIVATE_PROMOTION = gql`
  mutation activatePromotion($id: ID) {
    activatePromotion(_id: $id) {
      message
      success
    }
  }
`;

export const GET_PROMOTION_BY_ID_LIST = gql`
  query getPromotionsByIdList($id: [ID!], $customer: ID!) {
    promotionsByIdList(id: $id, customer: $customer) {
      _id
      name
      discount
      type
      min
      typeFormat
      availableUse
      active
      use
      payments {
        _id
        name
      }
      gasstation {
        _id
        name
      }
      productgas {
        _id
        name
      }
      customer {
        _id
        name
      }
      store {
        _id
        name
      }
      category {
        _id
        name
      }
      typeItem
    }
  }
`;

export const UPDATE_PROMOTION = gql`
  mutation updatePromotion(
    $id: ID!
    $name: String
    $gasstation: ID
    $customer: ID
    $productgas: ID
    $productgasGroup: ID
    $min: Float
    $max: Float
    $typeFormat: String
    $use: Int
    $useByClient: Int
    $dateinitial: Date
    $dateend: Date
    $payments: ID
    $user: ID
    $active: Boolean
    $type: String
    $discount: String
    $featured: Boolean
    $minimumValue: String
    $limitedForFirstPurchase: Boolean
    $image: String
    $typeItem: TypeItem
    $pontos: Float
  ) {
    updatePromotion(
      _id: $id
      name: $name
      gasstation: $gasstation
      customer: $customer
      productgas: $productgas
      productgasGroup: $productgasGroup
      min: $min
      max: $max
      typeFormat: $typeFormat
      use: $use
      useByClient: $useByClient
      dateinitial: $dateinitial
      dateend: $dateend
      payments: $payments
      user: $user
      active: $active
      type: $type
      discount: $discount
      featured: $featured
      minimumValue: $minimumValue
      limitedForFirstPurchase: $limitedForFirstPurchase
      image: $image
      typeItem: $typeItem
      pontos: $pontos
    ) {
      success
      message
    }
  }
`;

export const removeQueryPromotions = id => `
 mutation{
   removePromotion(_id: "${id}"){
     success message
   }
 }`;

export const getPromotionsId = id => `
{
  promotion(_id: "${id}"){
    _id
    name
    image
    liters
    litersMax
    use
    used
    useByClient
    concludedVouchers
    notConcludedVouchers
    canceledVouchers
    expiredVouchers
    availableUse
    dateinitial
    dateend
    limitedForFirstPurchase
    payments { _id name }
    gasstation { _id name timezone }
    store { _id name }
    category { _id name }
    productgas { _id name }
    type
    discount
    featured
    minimumValue
    typeItem
  }
}`;

export const GET_PROMOTION_PERFORMACE = gql`
  query getPromotionByPerformace(
    $customer: ID!
    $search: String
    $searchFields: [String]
    $page: Int!
    $perPage: Int!
    $dateStart: Date
    $dateEnd: Date
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
  ) {
    promotionByPerformance(
      customer: $customer
      search: $search
      searchFields: $searchFields
      page: $page
      perPage: $perPage
      startDate: $dateStart
      endDate: $dateEnd
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      docs {
        _id
        totalClient
        totalTransacao
        cashback
      }
      totalDocs
      totalPages
      page
    }
  }
`;
