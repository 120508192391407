import styled from 'styled-components';
import { Label } from 'reactstrap';

export const LabelStyled = styled(Label)`
  padding: 0px 24px;
`;

export const Required = styled.span`
  color: red;
  font-weight: bolder;
`;
