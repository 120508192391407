/* eslint-disable no-unused-vars */
import { request as graphql } from 'graphql-request';
import { authUserQuery, updateUserData } from '../queries/user';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_USER: 'user/SET',
  LOGIN_ERROR: 'user/LOGIN_ERROR',
  SET_TOKEN: 'user/TOKEN',
  SET_SUCCESS: 'user/SUCCESS'
};

const INITIAL_STATE = [];

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_USER:
      return action.user;
    case Types.LOGIN_ERROR:
      return [action.loginError];
    case Types.SET_SUCCESS:
      return action.success;

    default:
      return state;
  }
}

const setUser = user => ({
  type: Types.SET_USER,
  user
});

const setUserToken = token => ({
  type: Types.SET_TOKEN,
  token
});

const setLoginError = loginError => ({
  type: Types.LOGIN_ERROR,
  loginError
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setUser,
  setUserToken,
  setLoginError,

  login: (email, password) => {
    return dispatch => {
      graphql(config.URL_API, authUserQuery(email, password, 'PRIVATE'))
        .then(data => {
          const { token, refreshToken, user } = data.authUser;

          if (token) {
            const dataJs = {
              id: user._id,
              username: user.email,
              firstName: user.name,
              imagePerfil: '',
              customer: user.customer ? user.customer._id : null,
              role: user.role,
              type: user.customer ? user.customer.type : null,
              processManual: user.customer
                ? user.customer.processManual
                  ? user.customer.processManual
                  : false
                : false,
              store: user.store,
              gasstation: user.gasstation,
              token,
              refreshToken,
              distributor: user.distributor && user.distributor._id
            };
            localStorage.setItem('user', JSON.stringify(dataJs));
            dispatch(setUser(user));
            dispatch(setUserToken(token));
          } else {
            dispatch(setLoginError({ error: 'E-mail ou senha incorreto' }));
          }
        })
        .catch(error => {
          dispatch(setLoginError({ error: error.response.errors[0].message }));
        });
    };
  },
  logout: () => {
    return dispatch => {
      localStorage.removeItem('user');
      dispatch(setUser([]));
    };
  },
  updateUserData: (id, name) => {
    return dispatch => {
      graphql(
        config.URL_API,
        updateUserData(id, name),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updateUser;
          if (success) {
            const userStorage = localStorage.getItem('user');
            if (userStorage) {
              const user = JSON.parse(userStorage);
              user.firstName = name;
              ////console.log("AQUI >> ", user)
              localStorage.setItem('user', JSON.stringify(user));
              setUser(user);
              dispatch(
                setSuccess({ success: 'Usuário atualizado com sucesso' })
              );
            } else {
              ////console.log("ERRO >> ")
              dispatch(
                setLoginError({ error: 'Dados do usuário não encontrados' })
              );
            }
          } else {
            dispatch(setLoginError({ error: message }));
          }
        })
        .catch(error => {
          dispatch(setLoginError(error));
        });
    };
  },
  alterCustomer: user => {
    return dispatch => {
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setUser(user));
    };
  }
};
