/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input } from 'reactstrap';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { connect } from 'react-redux';
import { Creators as storeActions } from '../../../store/ducks/store';
import { Creators as gasstationActions } from '../../../store/ducks/gasstation';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { GroupBadge, GroupContainer } from '../styles';

const formatGroupLabel = data =>
  !!data.label && (
    <GroupContainer>
      <span>{data.label}</span>
      <GroupBadge>{data.options.length}</GroupBadge>
    </GroupContainer>
  );

export const SELECT_ALL_OPTION = {
  value: 'todos',
  label: 'Todas as Empresas'
};

function SelectEmpresa({
  user,
  store,
  gasstation,
  getStores,
  getGasStation,
  ismulti,
  disable,
  value,
  parentCallback,
  label,
  placeholder,
  getCustomers,
  customer,
  selectAllOption = false,
  selectAllOptionNull = false
}) {
  if (selectAllOptionNull) {
    SELECT_ALL_OPTION.value = null;
  }

  const [empresas, setEmpresas] = useState([]);

  function joinStoreGasstation() {
    let list = [];

    if (store && store.length > 0) {
      store.map(item => {
        list.push({
          value: item._id,
          label: item.name,
          type: 'store'
        });
      });
    }
    if (gasstation && gasstation.length > 0) {
      gasstation.map(item => {
        list.push({
          value: item._id,
          label: item.name,
          type: 'gasstation'
        });
      });
    }

    let groupedList = [];

    if (selectAllOption) {
      groupedList.push({
        options: [SELECT_ALL_OPTION]
      });
    }

    groupedList.push(
      {
        label: 'Lojas',
        options: list.filter(x => x.type === 'store')
      },
      {
        label: 'Postos',
        options: list.filter(x => x.type === 'gasstation')
      }
    );
    setEmpresas(groupedList);
  }

  function handleChange(opt) {
    if (ismulti) {
      const selectedAll = opt.some(x => x.value === SELECT_ALL_OPTION.value);

      let newOpts = [];
      empresas.forEach(emp => {
        let newOpt = {
          label: emp.label,
          options: []
        };
        newOpt.options = emp.options.map(item => ({
          ...item,
          isDisabled: selectedAll
        }));
        newOpts.push(newOpt);
      });

      setEmpresas(newOpts);

      parentCallback(
        selectedAll ? opt.filter(x => x.value === SELECT_ALL_OPTION.value) : opt
      );
    } else {
      parentCallback(opt);
    }
  }

  useEffect(() => {
    if (store) {
      joinStoreGasstation();
    }
  }, [store]);

  useEffect(() => {
    if (!gasstation.error && !gasstation.success) {
      joinStoreGasstation();
    }
  }, [gasstation]);

  useEffect(() => {
    getStores(user.customer);
    getGasStation(user.customer);
  }, []);

  useEffect(() => {
    if (customer) {
      getStores(customer.value);
      getGasStation(customer.value);
    }
  }, [customer]);

  return (
    <FormGroup>
      <Label for='exampleSelect'>{label}</Label>
      <Select
        isMulti={ismulti}
        closeMenuOnSelect={ismulti ? false : true}
        onChange={e => handleChange(e)}
        isSearchable={true}
        isDisabled={disable}
        value={value}
        placeholder={placeholder || label}
        options={empresas}
        noOptionsMessage={() => 'Não há empresas'}
        menuPortalTarget={document.body}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
        formatGroupLabel={formatGroupLabel}
      />
    </FormGroup>
  );
}

const mapStateToProps = state => ({
  store: state.store,
  gasstation: state.gasstation,
  user: state.user
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...storeActions, ...gasstationActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectEmpresa);
