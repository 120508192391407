import React from 'react';
import { toast as toastify } from 'react-toastify';
import { Row, Col } from 'reactstrap';

const successConfig = {
  autoClose: 2000,
  hideProgressBar: true,
  position: 'top-center'
};

const defaultConfig = {
  autoClose: 4000,
  hideProgressBar: false,
  position: 'top-center'
};

const iconProps = {
  style: {
    fontSize: '32px'
  }
};

const setIconClassName = type => {
  switch (type) {
    case 'success':
      return 'mdi mdi-check';
    case 'error':
      return 'mdi mdi-close-circle-outline';
    case 'info':
      return 'mdi mdi-information-outline';
    case 'warn':
      return 'mdi mdi-alert-circle-outline';
    default:
      return;
  }
};

const Container = ({ msg, type }) => (
  <Row>
    <Col xs={2} className={type === 'warn' ? 'text-dark' : 'text-white'}>
      <i className={setIconClassName(type)} {...iconProps}></i>
    </Col>
    <Col
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
    >
      <span className={type === 'warn' ? 'text-dark' : 'text-white'}>
        {msg}
      </span>
    </Col>
  </Row>
);
const saveSuccessMessage = 'Cadastro efetuado com sucesso!';
const updateSuccessMessage = 'Atualização efetuada com sucesso!';

const addErrorMessage = errorMsg =>
  `Houve um erro ao efetuar o cadastro: ${errorMsg}`;

const updateErrorMessage = errorMsg =>
  `Houve um erro ao efetuar a atualização: ${errorMsg}`;

const success = msg =>
  toastify.success(<Container msg={msg} type='success' />, successConfig);
const info = msg =>
  toastify.info(<Container msg={msg} type='info' />, defaultConfig);
const error = msg =>
  toastify.error(<Container msg={msg} type='error' />, defaultConfig);
const warning = msg =>
  toastify.warn(<Container msg={msg} type='warn' />, defaultConfig);
const warn = msg =>
  toastify.warn(<Container msg={msg} type='warn' />, defaultConfig);

const addSuccess = () => success(saveSuccessMessage);
const updateSuccess = () => success(updateSuccessMessage);

const addError = errorMsg => error(addErrorMessage(errorMsg));
const updateError = errorMsg => error(updateErrorMessage(errorMsg));
const toast = {
  success,
  info,
  error,
  warning,
  warn,
  addSuccess,
  updateSuccess,
  addError,
  updateError
};

export default toast;
