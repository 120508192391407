import styled from 'styled-components';

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GroupBadge = styled.span`
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
`;

export const CategoryPicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-right: 4px;
`;
