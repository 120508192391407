import React from 'react';
import { formatDate } from '../../helpers/date';

const dateFormat = 'DD/MM/YYYY [às] HH:mm:ss';

export const IdLabel = props => (
  <span>
    ID: <b>{props.children}</b>
  </span>
);

export const CreatedAtLabel = props => (
  <span>
    Data de Cadastro:{' '}
    <b>
      {props.children
        ? formatDate({
            date: props.children,
            format: dateFormat
          })
        : 'Não informado'}
    </b>
  </span>
);

export const UpdatedAtLabel = props => (
  <span>
    Data da Última Alteração:{' '}
    <b>
      {props.children
        ? formatDate({
            date: props.children,
            format: dateFormat
          })
        : 'Não informado'}
    </b>
  </span>
);

export const UserLabel = props => (
  <span>
    Cadastrado por: <b>{props.children || 'Não informado'}</b>
  </span>
);

export const UserUpdateLabel = props => (
  <span>
    Última Alteração por: <b>{props.children || 'Não informado'}</b>
  </span>
);

export const ActiveLabel = props => (
  <span>
    Ativo:{' '}
    <b>
      {props.children === true
        ? 'Sim'
        : props.children === false
          ? 'Não'
          : 'Não informado'}
    </b>
  </span>
);
