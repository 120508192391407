import React from 'react';
import PropTypes from 'prop-types';
import useStyles, { ItemsContainerStyled } from './styles';

import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiCurrencyUsd } from '@mdi/js';
import {
  Add,
  Delete,
  Remove,
  InfoSharp,
  Edit,
  Search,
  Visibility,
  DriveEtaSharp,
  PieChartSharp,
  AttachMoneySharp,
  WhatsApp,
  Nfc,
  FilterList,
  RecentActors
} from '@material-ui/icons';

const linkProps = {
  onClick: e => {
    e.preventDefault();
  }
};

const linkStyle = {
  style: {
    margin: -4
  }
};
const FAIconstyle = {
  fontSize: '0.7rem',
  border: 0,
  borderRadius: '50px',
  color: '#FFF',
  padding: '7px',
  cursor: 'pointer'
};

export function FilterIcon({ children }) {
  return (
    <span
      style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
    >
      <FilterList style={{ fontSize: 18 }} />
      {children}
    </span>
  );
}

export const ItemsContainer = props => (
  <ItemsContainerStyled>{props.children}</ItemsContainerStyled>
);

export const ActivateItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title={'Ativar'}
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Add className={styles.buttonGreen} />
    </button>
  );
  if (onClick) {
    return btn;
  }

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const DeleteItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title={'Apagar'}
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Delete className={styles.buttonRed} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

/**
 *
 * @param {{onClick?: ()=> void, to: string}} props
 * @returns
 */
export const InactivateItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title={'Inativar'}
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Remove className={styles.buttonRed} />
    </button>
  );
  if (onClick) {
    return btn;
  }

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const InfoItem = ({ onClick, to, size = 28 }) => {
  const styles = useStyles();
  const btn = (
    <button
      title={'Informações'}
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <InfoSharp style={{ fontSize: size }} className={styles.buttonBlue} />
    </button>
  );
  if (onClick) {
    return btn;
  }

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const EditItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title={'Editar'}
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Edit className={styles.buttonEdit} />
    </button>
  );
  if (onClick) {
    return btn;
  }

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const SearchItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Informações'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Search className={styles.buttonSearch} />
    </button>
  );
  if (to) {
    return (
      <Link {...linkStyle} {...(!to && linkProps)} to={to}>
        {btn}
      </Link>
    );
  }
  return btn;
};

export const EyeItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Ver'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Visibility className={styles.buttonBlue} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const EditValueItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      style={{ border: 0, backgroundColor: 'transparent', margin: 0 }}
      onClick={onClick}
    >
      <Icon
        className={styles.buttonGreen}
        path={mdiCurrencyUsd}
        size={1.1}
        horizontal
        vertical
      />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const ChangePasswordItem = ({ onClick, to }) => {
  const btn = (
    <button
      title='Alterar senha'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        className={`fas fa-key`}
        style={{ ...FAIconstyle, backgroundColor: '#0D47A1' }}
      ></i>
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const ChangeMailItem = ({ onClick, to }) => {
  const btn = (
    <button
      title='Alterar email'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        className={`fas fa-envelope`}
        style={{ ...FAIconstyle, backgroundColor: '#0D47A1' }}
      ></i>
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const ChangeUserItem = ({ onClick, to }) => {
  const btn = (
    <button
      title='Alterar email'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        class='fas fa-user'
        style={{ ...FAIconstyle, backgroundColor: '#0D47A1' }}
      />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const NfcItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Associar NFC'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Nfc className={styles.buttonLock} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const RemoveNfcItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Desassociar NFC'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <Nfc
        className={styles.buttonLock}
        style={{ backgroundColor: '#f44336' }}
      />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const CarsItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Veículos'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <DriveEtaSharp className={styles.buttonLock} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const DashboardItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Indicadores'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <PieChartSharp className={styles.buttonLock} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const ExtractItem = ({ onClick, title, to }) => {
  const styles = useStyles();
  const btn = (
    <Link to={to}>
      <button
        title={title || 'Extrato'}
        style={{ border: 0, backgroundColor: 'transparent' }}
        onClick={onClick}
      >
        <AttachMoneySharp className={styles.buttonLock} />
      </button>
    </Link>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const WhatsappItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Enviar Whatsapp'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <WhatsApp className={styles.buttonGreen} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const BlankItem = () => {
  const styles = useStyles();
  const btn = (
    <button style={{ border: 0, backgroundColor: 'transparent' }}>
      <WhatsApp className={styles.buttonBlank} />
    </button>
  );

  return <Link {...linkProps}>{btn}</Link>;
};

export const ActionsContainer = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      }}
    >
      {children}
    </div>
  );
};

export const BlockUserItem = ({ onClick, to }) => {
  const btn = (
    <button
      title='Bloquear Usuário'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        className={`fas fa-lock-open`}
        style={{ ...FAIconstyle, backgroundColor: '#0D47A1' }}
      ></i>
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const UnblockUserItem = ({ onClick, to }) => {
  const btn = (
    <button
      title='Desbloquear Usuário'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        className={`fas fa-lock`}
        style={{ ...FAIconstyle, backgroundColor: '#f44336' }}
      ></i>
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const ProfileItem = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Perfil'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <RecentActors className={styles.buttonLock} />
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const EditInviteCode = ({ onClick, to }) => {
  const styles = useStyles();
  const btn = (
    <button
      title='Codigo de indicação'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        className={`fas fa-hand-point-left`}
        style={{ ...FAIconstyle, backgroundColor: '#f44336' }}
      ></i>
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

export const EditFidelityCode = ({ onClick, to }) => {
  const btn = (
    <button
      title='Número de fidelidade'
      style={{ border: 0, backgroundColor: 'transparent' }}
      onClick={onClick}
    >
      <i
        className={`fas fa-quote-left`}
        style={{ ...FAIconstyle, backgroundColor: '#0D47A1' }}
      ></i>
    </button>
  );

  return (
    <Link {...linkStyle} {...(!to && linkProps)} to={to}>
      {btn}
    </Link>
  );
};

ActivateItem.propTypes = { onClick: PropTypes.func };
DeleteItem.propTypes = { onClick: PropTypes.func };
InactivateItem.propTypes = { onClick: PropTypes.func };
InfoItem.propTypes = { onClick: PropTypes.func };
EditItem.propTypes = { onClick: PropTypes.func };
SearchItem.propTypes = { onClick: PropTypes.func };
EyeItem.propTypes = { onClick: PropTypes.func };
EditValueItem.propTypes = { onClick: PropTypes.func };
ChangePasswordItem.propTypes = { onClick: PropTypes.func };
