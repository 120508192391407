import { request as graphql } from 'graphql-request';
import {
  getQueryListNotification,
  addQueryNotification
} from '../queries/notification';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_NOTIFICATION: 'notification/SET',
  SET_ERROR: 'notification/ERROR',
  SET_SUCCESS: 'notification/SUCCESS'
};

const INITIAL_STATE = [];

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_NOTIFICATION:
      return action.notification;
    case Types.SET_ERROR:
      return action.error;
    case Types.SET_SUCCESS:
      return action.success;
    default:
      return state;
  }
}

const setNotification = notification => ({
  type: Types.SET_NOTIFICATION,
  notification
});

const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setNotification,
  setError,
  setSuccess,
  getListNotification: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryListNotification(id),
        undefined,
        authGraphqlHeaders().headers
      ).then(data => {
        const { notifications } = data;
        dispatch(setNotification(notifications));
      });
    };
  },

  addNotification: (title, text, dataSend, active, user, customer) => {
    return dispatch => {
      graphql(
        config.URL_API,
        addQueryNotification(title, text, dataSend, active, user, customer),
        undefined,
        authGraphqlHeaders().headers
      ).then(data => {
        const { _id } = data.addNotification;
        ////console.log(data);
        if (_id) {
          dispatch(setSuccess({ success: 'Notificação enviada com sucesso!' }));
        } else {
          ////console.log('asd');
          dispatch(
            setError({ error: 'Houve um erro ao enviar a notificacao' })
          );
        }
      });
    };
  }
};
