/* eslint-disable no-unused-vars */
import { request as graphql } from 'graphql-request';
import {
  getQueryStore,
  setQueryStore,
  deletQueryStore,
  getQueryId,
  updateQuery
} from '../queries/store';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_STORE: 'store/SET',
  GET_STORE: 'store/GET ',
  SET_ERROR: 'store/ERROR',
  REMOVE_STORE: 'store/REMOVE',
  SET_SUCCESS: 'store/SET_SUCCESS'
};

const INITIAL_STATE = [];

export default function store(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_STORE:
      return [...state, action.store];

    case Types.GET_STORE:
      return action.store;

    case Types.REMOVE_STORE:
      return [...state, action.store];
    case Types.SET_SUCCESS:
      return action.store;
    case Types.SET_ERROR:
      return action.error;
    default:
      return state;
  }
}

const setStores = store => ({
  type: Types.SET_STORE,
  store
});

const removeStore = store => ({
  type: Types.REMOVE_CUSTOME,
  store
});

const getStore = store => ({
  type: Types.GET_STORE,
  store
});

const setStoreError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = store => ({
  type: Types.SET_SUCCESS,
  store
});

export const Creators = {
  setStores,
  getStore,
  removeStore,
  setStoreError,
  setSuccess,
  getStores: (id, active) => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryStore(id, active),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { storesCustomer } = data;
          dispatch(getStore(storesCustomer.docs));
        })
        .catch(error => {
          dispatch(setStoreError(error));
        });
    };
  },

  setStore: (
    name,
    cnpj,
    razaosocial,
    city,
    cep,
    uf,
    address,
    phone,
    numero,
    lat,
    lng,
    image,
    neighborhood,
    customer,
    category,
    urlOther,
    whatsapp,
    bio,
    facebook,
    instagram,
    site
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        setQueryStore(
          name,
          cnpj,
          razaosocial,
          city,
          cep,
          uf,
          address,
          phone,
          numero,
          lat,
          lng,
          image,
          neighborhood,
          customer,
          category,
          urlOther,
          whatsapp,
          bio,
          facebook,
          instagram,
          site
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { addStore } = data;
          if (addStore._id) {
            dispatch(setSuccess({ success: 'Loja cadastrada com sucesso' }));
          } else if (addStore.error) {
            dispatch(setStoreError({ error: addStore.error }));
          }
        })
        .catch(error => {
          dispatch(setStoreError(error));
        });
    };
  },

  delStore: (id, customer, active) => {
    return dispatch => {
      graphql(
        config.URL_API,
        deletQueryStore(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success } = data.removeStore;
          if (success) {
            dispatch(setSuccess({ success: 'Loja deletada com sucesso' }));
            graphql(
              config.URL_API,
              getQueryStore(customer, true),
              undefined,
              authGraphqlHeaders().headers
            )
              .then(data => {
                const { storesCustomer } = data;
                dispatch(getStore(storesCustomer));
              })
              .catch(error => {
                dispatch(setStoreError(error));
              });
          } else {
            dispatch(setStoreError({ error: 'Erro ao deletar a loja' }));
          }
        })
        .catch(error => {
          dispatch(setStoreError({ error: 'Erro ao deletar a loja' }));
        });
    };
  },
  getStoreIds: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryId(id),
        undefined,
        authGraphqlHeaders().headers
      ).then(data => {
        const { store } = data;

        if (store) {
          dispatch(getStore(store));
        } else {
          dispatch(setStoreError({ error: 'Erro ao buscar a loja' }));
        }
      });
    };
  },
  updateStore: (
    id,
    name,
    cnpj,
    razaosocial,
    city,
    cep,
    uf,
    address,
    phone,
    numero,
    lat,
    lng,
    image,
    neighborhood,
    customer,
    category,
    urlOther,
    whatsapp,
    bio,
    facebook,
    instagram,
    site
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        updateQuery(
          id,
          name,
          cnpj,
          razaosocial,
          city,
          cep,
          uf,
          address,
          phone,
          numero,
          lat,
          lng,
          image,
          neighborhood,
          customer,
          category,
          urlOther,
          whatsapp,
          bio,
          facebook,
          instagram,
          site
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updateStore;

          if (success) {
            dispatch(setSuccess({ success: 'Loja atualizada com sucesso' }));
          } else {
            dispatch(setStoreError(message));
          }
        })
        .catch(error => {
          dispatch(setStoreError(error));
        });
    };
  }
};
