import { createTheme } from '@material-ui/core/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#0044A5',
    },
    secondary: {
      main: '#0044A5',
    },
    error: {
      main: '#FF0037',
    },
    success: {
      main: '#02C39A',
    },
    warning: {
      main: '#FFAE00',
    },
  },
});

export const styledTheme = {
  primary: '#0044A5',
  secondary: '#FF5C00',
  error: '#FF0037',
  success: '#02C39A',
  warning: '#FFAE00',
  text: '#333333',
};

export default theme;
