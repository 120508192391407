import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  buttonRed: {
    fontSize: 25,
    border: 0,
    borderRadius: '50px',
    backgroundColor: '#f44336',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer'
  },
  buttonBlue: {
    fontSize: 35,
    border: 0,
    borderRadius: '50px',
    backgroundColor: 'transparent',
    color: '#0D47A1',
    padding: '0px',
    cursor: 'pointer',
    margin: -2
  },
  buttonEdit: {
    fontSize: 35,
    border: 0,
    borderRadius: '50px',
    backgroundColor: '#6c757d',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer'
  },
  buttonLock: {
    fontSize: 25,
    border: 0,
    borderRadius: '50px',
    backgroundColor: '#0D47A1',
    color: '#FFF',
    padding: '5px',
    cursor: 'pointer'
  },
  buttonSearch: {
    fontSize: 25,
    border: 0,
    borderRadius: '50px',
    backgroundColor: '#0D47A1',
    color: 'white',
    padding: '5px',
    cursor: 'pointer'
  },
  buttonGreen: {
    fontSize: 35,
    border: 0,
    borderRadius: '50px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer'
  },
  simpleButton: {
    fontSize: 20,
    border: 0,
    color: '#555',
    cursor: 'pointer'
  },
  buttonBlank: {
    fontSize: 20,
    border: 0,
    borderRadius: '50px',
    backgroundColor: 'transparent',
    color: 'transparent',
    padding: '5px',
    cursor: 'default'
  }
}));

export const ItemsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export default useStyles;
