/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import { request as graphql } from 'graphql-request';
import {
  getCustomers,
  setQueryCustomers,
  deleteQueryCustomer,
  getQueryCustomersId,
  updateQueryCustomer
} from '../queries/customer';

// import { GRAPHQL_API } from 'dotenv';
// import { GraphQLBoolean } from 'graphql';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_CUSTOMER: 'customer/SET',
  GET_CUSTOMER: 'customer/GET ',
  SET_ERROR: 'customer/ERROR',
  REMOVE_CUSTOMER: 'customer/REMOVE'
};

const INITIAL_STATE = [];

export default function customer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_CUSTOMER:
      return action.customer;

    case Types.GET_CUSTOMER:
      return action.customer;

    case Types.REMOVE_CUSTOME:
      return [...state, action.customer];
    default:
      return state;
  }
}

const setCustomers = customer => ({
  type: Types.SET_CUSTOMER,
  customer
});
const removeCustomer = customer => ({
  type: Types.REMOVE_CUSTOME,
  customer
});

const getCustomersD = customer => ({
  type: Types.GET_CUSTOMER,
  customer
});

const setCustomerError = error => ({
  type: Types.SET_ERROR,
  error
});

export const Creators = {
  setCustomers,
  getCustomersD,
  removeCustomer,

  getCustomer: () => {
    return dispatch => {
      graphql(
        config.URL_API,
        getCustomers(),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { customers } = data;
          dispatch(getCustomersD(customers));
        })
        .catch(error => {
          dispatch(setCustomerError(error));
        });
    };
  },

  getCustomersId: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryCustomersId(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { customer } = data;
          dispatch(setCustomers(customer));
        })
        .catch(error => {
          dispatch(setCustomerError(error));
        });
    };
  },
  updateCustomer: (
    id,
    name,
    email,
    active,
    phone,
    primaryLogo,
    secondaryLogo,
    site,
    whatsapp,
    timezone
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        updateQueryCustomer(
          id,
          name,
          email,
          active,
          phone,
          primaryLogo,
          secondaryLogo,
          site,
          whatsapp,
          timezone
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success } = data.updateCustomer;
          if (success) {
            graphql(config.URL_API, getCustomers()).then(data => {
              const { customers } = data;
              dispatch(setCustomers(customers));
            });
          }
        })
        .catch(error => {
          dispatch(setCustomerError(error));
        });
    };
  },

  delCustomer: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        deleteQueryCustomer(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          // ////console.log(data);
          const { success, message } = data.removeCustomer;

          if (success) {
            graphql(
              config.URL_API,
              getCustomers(),
              undefined,
              authGraphqlHeaders().headers
            ).then(data => {
              const { customers } = data;
              dispatch(setCustomers(customers));
            });
          } else {
            dispatch(setCustomerError(message));
          }
        })
        .catch(error => {
          dispatch(setCustomerError(error));
        });
    };
  },

  setCustomer: (
    id,
    name,
    email,
    active,
    phone,
    primaryLogo,
    secondaryLogo,
    site,
    whatsapp,
    timezone
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        setQueryCustomers(
          id,
          name,
          email,
          active,
          phone,
          primaryLogo,
          secondaryLogo,
          site,
          whatsapp,
          timezone
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { customers } = data;
          dispatch(setCustomers(customers));
        })
        .catch(error => {
          dispatch(setCustomerError(error));
        });
    };
  }
};
