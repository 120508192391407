/* eslint-disable no-unused-vars */
import { request as graphql } from 'graphql-request';
import {
  setQueryUser,
  getQueryUser,
  getQueryUsers,
  removeQueryUser,
  setQueryUserADMIN
} from '../queries/user';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_USER: 'userstore/SET',
  LOGIN_ERROR: 'userstore/LOGIN_ERROR',
  SET_SUCCESS: 'userstore/SUCCESS'
};

const INITIAL_STATE = [];

export default function userstore(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_USER:
      return action.user;
    case Types.LOGIN_ERROR:
      return action.loginError;

    case Types.SET_SUCCESS:
      return action.success;

    default:
      return state;
  }
}

const setUser = user => ({
  type: Types.SET_USER,
  user
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

const setLoginError = loginError => ({
  type: Types.LOGIN_ERROR,
  loginError
});

export const Creators = {
  setUser,
  setSuccess,
  setLoginError,

  addUser: (name, email, password, customer, role, store) => {
    return dispatch => {
      graphql(
        config.URL_API,
        setQueryUser(name, email, password, customer, role, store),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { addUser } = data;

          if (addUser._id) {
            dispatch(setSuccess({ success: 'Usuário cadastrado com sucesso' }));
          } else {
            dispatch(
              setLoginError({
                error:
                  'Houve um erro ao cadastrar o usuário, já existe um usuário com este e-mail'
              })
            );
          }
        })
        .catch(error => {
          dispatch(
            setLoginError({
              error:
                'Houve um erro ao cadastrar o usuário, favor tentar novamente'
            })
          );
        });
    };
  },
  addUserAdmin: (name, email, password, customer, role) => {
    return dispatch => {
      graphql(
        config.URL_API,
        setQueryUserADMIN(name, email, password, customer, role),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { addUser } = data;

          if (addUser._id) {
            dispatch(setSuccess({ success: 'Usuário cadastrado com sucesso' }));
          } else {
            dispatch(
              setLoginError({
                error:
                  'Houve um erro ao cadastrar o usuário, já existe um usuário com este e-mail'
              })
            );
          }
        })
        .catch(error => {
          ////console.log(error);
          dispatch(
            setLoginError({
              error:
                'Houve um erro ao cadastrar o usuário, favor tentar novamente'
            })
          );
        });
    };
  },
  getListUser: id => {
    return dispatch => {
      graphql(config.URL_API, getQueryUser(id), authGraphqlHeaders().headers)
        .then(data => {
          const { userCustomer } = data;

          dispatch(setUser(userCustomer));
        })
        .catch(error => {
          dispatch(
            setLoginError({
              error:
                'Houve um erro ao cadastrar o usuário, favor tentar novamente'
            })
          );
        });
    };
  },
  getListUsers: () => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryUsers(),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { users } = data;

          dispatch(setUser(users));
        })
        .catch(error => {
          dispatch(
            setLoginError({
              error:
                'Houve um erro ao cadastrar o usuário, favor tentar novamente'
            })
          );
        });
    };
  },
  delUser: id => {
    return dispatch => {
      graphql(config.URL_API, removeQueryUser(id), authGraphqlHeaders().headers)
        .then(data => {
          const { success, message } = data.removeUser;
          if (success) {
            dispatch(setSuccess({ success: 'Usuário deletado com sucesso' }));
          } else {
            dispatch(
              setLoginError({
                error:
                  'Houve um erro ao deletar o usuário, favor tentar novamente'
              })
            );
          }
        })
        .catch(error => {
          dispatch(
            setLoginError({
              error:
                'Houve um erro ao cadastrar o usuário, favor tentar novamente'
            })
          );
        });
    };
  }
};
