import { gql } from '@apollo/client';

export const SEND_PUSH_NOTIFICATION = gql`
  mutation sendMessage(
    $title: String!
    $message: String!
    $customer: ID!
    $client: [ID]!
    $user: ID
  ) {
    sendMessage(
      title: $title
      message: $message
      customer: $customer
      client: $client
      type: "push"
      user: $user
    ) {
      title
    }
  }
`;

export const SEND_SMS_NOTIFICATION = gql`
  mutation sendMessage(
    $title: String!
    $message: String!
    $customer: ID!
    $client: [ID]!
    $user: ID
  ) {
    sendMessage(
      title: $title
      message: $message
      customer: $customer
      client: $client
      type: "sms"
      user: $user
    ) {
      title
    }
  }
`;

export const SEND_NOTIFICATION_AND_FILTER_CLIENTS = gql`
  mutation sendNotification($inputs: SendMessageFilteredClientsInput!) {
    sendMessageFilteredClients(inputs: $inputs) {
      message
      success
    }
  }
`;

export const GET_MESSAGES = gql`
  query getMessages($customer: ID!) {
    messages(customer: $customer) {
      text
      title
      type
      views
      sends
      createdAt
      autoSent
      category
    }
  }
`;

export const GET_MESSAGES_PAGINATE = gql`
  query getMessagesPaginate(
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
    $customer: ID!
    $autoSent: Boolean
    $dateStart: Date
    $dateEnd: Date
  ) {
    messagesPaginate(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields
      customer: $customer
      autoSent: $autoSent
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      totalDocs
      page
      totalPages
      docs {
        text
        title
        type
        views
        sends
        createdAt
        autoSent
        category
        dateSend
      }
    }
  }
`;
