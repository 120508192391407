import { request as graphql } from 'graphql-request';
import {
  getQueryCategory,
  setQueryCategory,
  delQueryCategory,
  getQueryCategoryId,
  updateQueryCategory
} from '../queries/category';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_CATEGORY: 'category/SET',
  GET_CATEGORY: 'category/GET',
  SET_ERROR: 'category/ERROR',
  SET_SUCCESS: 'category/SUCCESS'
};

const INITIAL_STATE = [];

export default function category(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_CATEGORY:
      return action.category;
    case Types.GET_CATEGORY:
      return action.category;
    case Types.SET_ERROR:
      return action.error;
    case Types.SET_SUCCESS:
      return action.success;
    case Types.REMOVE_CATEGORY:
      return action.category;
    default:
      return state;
  }
}

const setCategory = category => ({
  type: Types.SET_CATEGORY,
  category
});

const getCategory = category => ({
  type: Types.GET_CATEGORY,
  category
});

const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

const removeCategory = category => ({
  type: Types.REMOVE_CATEGORY,
  category
});

export const Creators = {
  setCategory,
  getCategory,
  setError,
  setSuccess,
  getCategorys: (id, type) => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryCategory(id, type),
        undefined,
        authGraphqlHeaders().headers
      ).then(data => {
        const { categoryCustomer } = data;
        dispatch(setCategory(categoryCustomer));
        //console.log('entrando e saindo', categoryCustomer);
        //console.log('enrtando e saindo', data);
      });
    };
  },

  addCategory: (
    name,
    description,
    image,
    active = true,
    parentCategory,
    customer,
    principal
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        setQueryCategory(
          name,
          description,
          image,
          active,
          parentCategory,
          customer,
          principal
        ),
        undefined,
        authGraphqlHeaders().headers
      ).then(data => {
        const { addCategory } = data;
        ////console.log(addCategory);
        if (addCategory._id) {
          dispatch(setSuccess({ success: 'Categoria cadastrada com sucesso' }));
        } else {
          ////console.log('asd');
          dispatch(
            setError({ error: 'Houve uma falha ao salvar a categoria' })
          );
        }
      });
    };
  },

  deleteCategory: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        delQueryCategory(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.removeCategory;
          ////console.log(category);
          if (success) {
            dispatch(setSuccess({ success: message }));
            dispatch(removeCategory);
          } else {
            dispatch(setError({ success: message }));
          }
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve uma falha ao deletar a categoria' })
          );
        });
    };
  },

  getCategorysId: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryCategoryId(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { category } = data;
          ////console.log(category);
          category._id
            ? dispatch(getCategory(category))
            : dispatch(
                setError({ error: 'Houve uma falha ao busca a categoria' })
              );
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve uma falha ao buscar a categoria' })
          );
        });
    };
  },
  updateCategory: (
    id,
    name,
    description,
    image,
    active = true,
    parentCategory,
    principal
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        updateQueryCategory(
          id,
          name,
          description,
          image,
          active,
          parentCategory,
          principal
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updateCategory;
          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error =>
          dispatch(
            setError({ error: 'Houve um erro ao atualizar a categoria' })
          )
        );
    };
  }
};
