import React from 'react';
// import { Button as MUIButton, Grid, ButtonProps } from "@material-ui/core"
import { Badge, Button as BootstrapButton, ButtonProps } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { EditItem } from '../../Table/ActionItems';

/**
 * @param {...ButtonProps} props
 */
export const Button = props => {
  const defaultProps = {
    size: 'small',
    variant: 'contained'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {props.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const EditButton = props => {
  const defaultProps = {
    color: 'primary',
    startIcon: <i className='fas fa-edit'></i>,
    children: 'Editar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const SaveButton = props => {
  const defaultProps = {
    color: 'success',
    startIcon: <i className='fas fa-save'></i>,
    children: 'Salvar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @typedef {"save" |
 * "edit" |
 * "lock" |
 * "clear" |
 * "upload" |
 * "cancel" |
 * "check"|
 * "search"|
 * "add" |
 * "filter"
 * } ButtonTypes
 *
 *
 * @typedef {Object} CibusButton
 * @property {import('react').ReactComponentElement} props.children
 * @property {number} count Usado apenas quando `buttonType === "filter"`
 * @property {ButtonTypes} buttonType
 */

/**
 * @param {CibusButton & ButtonProps} props
 */
export const CibusButton = ({ buttonType, children, count, ...rest }) => {
  const defaultProps = {
    save: {
      color: 'success',
      startIcon: <i className='fas fa-save'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Salvar'}
          </>
        );
      }
    },
    edit: {
      color: 'primary',
      startIcon: <i className='fas fa-edit'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Editar'}
          </>
        );
      }
    },
    filter: {
      color: 'primary',
      startIcon: <i className='fas fa-filter'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Filtrar'}
            {!!count && (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Badge pill>{count}</Badge>
              </>
            )}
          </>
        );
      }
    },
    add: {
      color: 'success',
      startIcon: <i className='fas fa-plus'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Adicionar'}
          </>
        );
      }
    },
    search: {
      color: 'success',
      startIcon: <i className='fas fa-search'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Pesquisar'}
          </>
        );
      }
    },
    check: {
      color: 'success',
      startIcon: <i className='fas fa-check'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Confirmar'}
          </>
        );
      }
    },
    cancel: {
      color: 'secondary',
      startIcon: <i className='fas fa-times'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Cancelar'}
          </>
        );
      }
    },
    upload: {
      color: 'primary',
      startIcon: <i className='fas fa-upload'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Escolher Arquivo'}
          </>
        );
      }
    },
    clear: {
      color: 'secondary',
      startIcon: <i className='fas fa-eraser'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Apagar'}
          </>
        );
      }
    },
    lock: {
      color: 'primary',
      startIcon: <i className='fas fa-lock'></i>,
      get children() {
        return (
          <>
            {this.startIcon} {children ?? 'Alterar Senha'}
          </>
        );
      }
    }
  };
  const config = defaultProps[buttonType];
  const customButtonProps = Object.assign({ color: config.color }, rest);
  return (
    <BootstrapButton {...customButtonProps}>{config.children}</BootstrapButton>
  );
};

export const SaveCloseButton = ({
  toggle,
  labelSave,
  labelBack,
  onSave,
  onBack,
  disabled = false
}) => {
  return (
    <Row>
      <Col lg={2}></Col>
      <Col lg={2}></Col>
      <Col lg={2}></Col>
      <Col lg={2}></Col>
      <Col lg={2}>
        <BootstrapButton
          block
          onClick={onSave}
          disabled={disabled}
          color='success'
        >
          <i className='fas fa-save'></i> {labelSave}
        </BootstrapButton>
      </Col>
      <Col lg={2}>
        <BootstrapButton
          disabled={disabled}
          block
          color='secondary'
          onClick={onBack}
        >
          <i className={`fas fa-${toggle ? 'chevron-left' : 'times'}`}></i>{' '}
          {labelBack}
        </BootstrapButton>
      </Col>
    </Row>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const FilterButton = props => {
  const defaultProps = {
    color: 'primary',
    startIcon: <i className='fas fa-filter'></i>,
    children: 'Filtrar'
  };

  const customProps = Object.assign(defaultProps, props);
  const StartIcon = customProps.startIcon;
  delete customProps.startIcon;
  return (
    <BootstrapButton {...customProps}>
      {StartIcon} {customProps.children}
      {!!props.count && (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Badge pill>{props.count}</Badge>
        </>
      )}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const AddButton = props => {
  const defaultProps = {
    color: 'success',
    startIcon: <i className='fas fa-plus'></i>,
    children: 'Adicionar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const SearchButton = props => {
  const defaultProps = {
    color: 'success',
    startIcon: <i className='fas fa-search'></i>,
    children: 'Pesquisar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const CheckButton = props => {
  const defaultProps = {
    color: 'success',
    startIcon: <i className='fas fa-check'></i>,
    children: 'Confirmar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const CancelButton = props => {
  const defaultProps = {
    color: 'secondary',
    startIcon: <i className='fas fa-times'></i>,
    children: 'Cancelar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const LockButton = props => {
  const defaultProps = {
    color: 'primary',
    startIcon: <i className='fas fa-lock'></i>,
    children: 'Alterar Senha'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const UploadButton = props => {
  const defaultProps = {
    color: 'primary',
    startIcon: <i className='fas fa-upload'></i>,
    children: 'Escolher Arquivo'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

/**
 * @param {...ButtonProps} props
 */
export const ClearButton = props => {
  const defaultProps = {
    color: 'secondary',
    startIcon: <i className='fas fa-eraser'></i>,
    children: 'Apagar'
  };

  const customProps = Object.assign(defaultProps, props);

  return (
    <BootstrapButton {...customProps}>
      {customProps.startIcon} {customProps.children}
    </BootstrapButton>
  );
};

export const ReportButtons = ({
  confirmLabel = 'Gerar Relatório',
  cancelLabel = 'Limpar Campos',
  onConfirm,
  onCancel,
  confirmIcon = <i className='fas fa-check'></i>,
  cancelIcon = <i className='fas fa-eraser'></i>,
  width = 6
}) => {
  return (
    <Row>
      <Col md={width / 2}>
        <BootstrapButton color='success' block onClick={onConfirm}>
          {confirmIcon} {confirmLabel}
        </BootstrapButton>
      </Col>
      <Col md={width / 2}>
        <BootstrapButton block color='secondary' onClick={onCancel}>
          {cancelIcon} {cancelLabel}
        </BootstrapButton>
      </Col>
      <Col></Col>
    </Row>
  );
};
