/* eslint-disable no-unused-vars */
import { request as graphql } from 'graphql-request';
import {
  GetQueryGasStation,
  AddQueryGasStation,
  deleteQueryGasStation,
  UpdateQueryGasStation,
  GetQuerGasStationId
} from '../queries/gasstation';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_GASSTATION: 'gasstation/SET',
  SET_ERROR: 'gasstation/ERROR',
  SET_SUCCESS: 'gasstation/SUCCESS'
};

const INITIAL_STATE = [];

export default function gasstation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_GASSTATION:
      return action.gasstation;
    case Types.SET_SUCCESS:
      return action.success;
    case Types.SET_ERROR:
      return action.error;
    default:
      return state;
  }
}

const setGasstation = gasstation => ({
  type: Types.SET_GASSTATION,
  gasstation
});
const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setGasstation,
  setError,
  setSuccess,
  getGasStation: customer => {
    return dispatch => {
      graphql(
        config.URL_API,
        GetQueryGasStation(customer),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { gasstationsCustomer } = data;
          dispatch(setGasstation(gasstationsCustomer));
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao buscar os postos' }));
        });
    };
  },
  addGasStation: (
    name,
    cnpj,
    companyName,
    flag,
    City,
    cep,
    uf,
    address,
    phone,
    facebook,
    instagram,
    site,
    whatsapp,
    convenience,
    oilChange,
    N,
    lat,
    lng,
    image,
    customer,
    productgas,
    neighborhood,
    timezone
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        AddQueryGasStation(
          name,
          cnpj,
          companyName,
          flag,
          City,
          cep,
          uf,
          address,
          phone,
          facebook,
          instagram,
          site,
          whatsapp,
          convenience,
          oilChange,
          N,
          lat,
          lng,
          image,
          customer,
          productgas,
          neighborhood,
          timezone
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { _id } = data.addGasStation;
          if (_id) {
            dispatch(setSuccess({ success: 'Posto cadastrado com sucesso!' }));
          } else {
            if (data.addGasStation.error)
              dispatch(setError({ error: data.addGasStation.error }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao cadastrar o posto' }));
        });
    };
  },
  removeGasStation: id => {
    return dispatch => {
      graphql(config.URL_API, deleteQueryGasStation(id)).then(data => {
        const { success, message } = data.removeGasStation;

        if (success) {
          dispatch(setSuccess({ success: 'Posto Excluído com sucesso!' }));
        } else {
          dispatch(setError({ error: 'Houve um erro ao excluir o posto' }));
        }
      });
    };
  },
  updateGasStation: (
    id,
    name,
    cnpj,
    companyName,
    flag,
    City,
    cep,
    uf,
    address,
    phone,
    facebook,
    instagram,
    site,
    whatsapp,
    N,
    lat,
    lng,
    image,
    customer,
    productgas,
    neighborhood,
    timezone
  ) => {
    return dispatch => {
      ////console.log('PRODUTOOO > ', productgas);
      graphql(
        config.URL_API,
        UpdateQueryGasStation(
          id,
          name,
          cnpj,
          companyName,
          flag,
          City,
          cep,
          uf,
          address,
          phone,
          facebook,
          instagram,
          site,
          whatsapp,
          N,
          lat,
          lng,
          image,
          customer,
          productgas,
          neighborhood,
          timezone
        )
      )
        .then(data => {
          const { success, message } = data.updateGasStation;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao cadastrar o posto' }));
        });
    };
  },
  getGasStationId: id => {
    return dispatch => {
      graphql(config.URL_API, GetQuerGasStationId(id))
        .then(data => {
          const { _id } = data.gasstation;

          if (_id) {
            dispatch(setGasstation(data.gasstation));
          } else {
            dispatch(setError({ error: 'Houve um erro ao buscar o posto' }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao buscar o posto' }));
        });
    };
  }
};
