import store from '../store';
import { Creators as actionUser } from '../store/ducks/user';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  //console.log(user)
  if (!user) {
    return false;
  } else if (!user.refreshToken) {
    return false;
  }
  store.dispatch(actionUser.setUser(user));
  // const decoded = jwtDecode(user.token);
  // const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //   //console.warn('access token expired');
  //   return false;
  // } else {
  return true;
  // }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = localStorage.getItem('user');

  return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser };
