/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { Creators as productGasCreate } from '../../../store/ducks/productgas';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { GasIcon } from './styles';

export const SELECT_ALL_OPTION = {
  value: 'todos',
  label: 'Todos os Combustíveis'
};

const formatOptionLabel = ({ label, value, icon, isDisabled }) => (
  <span>
    {!!icon && <GasIcon isDisabled={isDisabled} src={icon} />}
    <span>{label}</span>
  </span>
);

function SelectProductGas({
  user,
  productgas,
  ismulti,
  disable,
  value,
  productRefAsValue,
  parentCallback,
  label,
  placeholder,
  getProductGas,
  selectAllOption,
  showOptionsWithIcons = false,
  isClearable = false
}) {
  const [productGas, setProductGas] = useState([]);

  useEffect(() => {
    if (productgas) {
      if (!productgas.error) {
        if (!productgas.success) {
          let listProductGas = [];
          if (selectAllOption && productgas.length > 1) {
            listProductGas.push(SELECT_ALL_OPTION);
          }
          if (productgas.length > 0) {
            productgas
              .filter(x => x.active)
              .map(item => {
                const data = {
                  value: productRefAsValue ? item.ref : item._id,
                  label: item.name,
                  icon: item.icon
                };

                listProductGas.push(data);
              });

            setProductGas(listProductGas);
          }
        }
      }
    }
  }, [productgas]);
  function change(e) {
    if (selectAllOption) {
      let selecionouSELECT_ALL_OPTION = false;
      if (e) {
        for (let i = 0; i < e.length; i++) {
          if (e[i].value === null) {
            selecionouSELECT_ALL_OPTION = true;
            parentCallback([e[i]]);
            break;
          }
        }
      }
      if (selecionouSELECT_ALL_OPTION) {
        productGas.map(item => {
          if (item.value !== null) item.isDisabled = true;
        });
        return;
      } else {
        productGas.map(item => {
          item.isDisabled = false;
        });
      }
    }

    if (parentCallback) {
      parentCallback(e);
    }
  }
  useEffect(() => {
    getProductGas(user.customer);
  }, []);

  return (
    <FormGroup>
      <Label for='exampleSelect'>{label}</Label>
      <Select
        isMulti={ismulti}
        closeMenuOnSelect={ismulti ? false : true}
        onChange={e => change(e)}
        placeholder={placeholder || label}
        isSearchable={true}
        isDisabled={disable}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
        value={value}
        options={productGas}
        noOptionsMessage={() => 'Não há combustíveis'}
        formatOptionLabel={showOptionsWithIcons ? formatOptionLabel : null}
        isClearable={isClearable}
      />
    </FormGroup>
  );
}

const mapStateToProps = state => ({
  productgas: state.productgas,
  user: state.user
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...productGasCreate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectProductGas);
