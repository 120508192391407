import { gql } from '@apollo/client';

export const GetQueryProductGas = customer => `
{
    productgasCustomer(customer:  "${customer}"){
        _id name icon ref createdAd updatedAt active
        productgasGroup { _id name }
    }
}
`;

export const AddQueryProductGas = (name, icon, customer, ref) => `
mutation{
    addProductGas(name:"${name}"
    icon:"${icon}"
    customer:"${customer}"
    active:${true}
    ref:"${ref}"){
        _id
    }
}
`;
export const UpdateProductGas = (id, name, icon, customer, ref) => `
mutation{
    updateProductGas(
        _id: "${id}"
        name:"${name}"
    icon:"${icon}"
    customer:"${customer}"
    active:${true}
    ref:"${ref}"){
        success message
    }
}
`;

export const InactivateQueryProductGas = id => `
mutation{
    removeProductGas(_id:"${id}")
    {
        success message
    }
}`;

export const ActivateQueryProductGas = id => `
mutation{
    activateProductGas(_id:"${id}")
    {
        success message
    }
}`;

export const GetProductGasId = id => `
{
    productgas(_id:"${id}"){
        _id name icon ref active 
        unit{
          _id 
          name
          symbol        
        }
        productgasGroup {
          _id
          name
        }
    }
}
`;

export const GET_PRODUCT_GAS_FIDELITY = gql`
  query ProductGasFidelityMethod(
    $customer: ID
    $gasstations: [ID]
    $fidelityMethod: String
  ) {
    productGasFidelityMethod(
      customer: $customer
      gasstations: $gasstations
      fidelityMethod: $fidelityMethod
    ) {
      _id
      name
    }
  }
`;

export const GET_PRODUCTS_CUSTOMER = gql`
  query getProductsCustomer($customer: ID!) {
    productgasCustomer(customer: $customer) {
      _id
      name
      icon
      ref
      createdAd
      updatedAt
      active
      productgasGroup {
        _id
        name
      }
      price {
        _id
        value
        fidelityMethod
        gasstation {
          _id
        }
      }
      prices {
        _id
        value
        fidelityMethod
        gasstation {
          _id
        }
      }
    }
  }
`;
export const GET_PRODUCT_GAS = gql`
  query getProductGas($_id: ID!) {
    productgas(_id: $_id) {
      _id
      name
      icon
      ref
      unit {
        _id
        name
        symbol
      }
      productgasGroup {
        _id
        name
      }
      active
    }
  }
`;

export const ADD_PRODUCT_GAS = gql`
  mutation addProductGas(
    $name: String!
    $icon: String!
    $customer: ID!
    $ref: String!
    $unit: ID
    $productgasGroup: ID
  ) {
    addProductGas(
      name: $name
      icon: $icon
      customer: $customer
      ref: $ref
      unit: $unit
      productgasGroup: $productgasGroup
    ) {
      _id
    }
  }
`;
export const UPDATE_PRODUCT_GAS = gql`
  mutation updateProductGas(
    $_id: ID
    $name: String!
    $icon: String!
    $customer: ID!
    $ref: String!
    $unit: ID
    $productgasGroup: ID
  ) {
    updateProductGas(
      _id: $_id
      name: $name
      icon: $icon
      customer: $customer
      ref: $ref
      unit: $unit
      productgasGroup: $productgasGroup
    ) {
      success
    }
  }
`;
