import { gql } from '@apollo/client';

export const addQueryPrice = (
  value,
  discount,
  gasstation,
  product,
  type,
  priceWithDiscount,
  customer,
  priceDeb,
  discountDeb,
  priceCar,
  discountCar,
  priceCreWithDiscount,
  priceDebWithDiscount,

  //REF 1
  priceCust1,
  discountCust1,
  priceWithDiscountCust1,

  //REF 2
  priceCust2,
  discountCust2,
  priceWithDiscountCust2,

  //REF 3
  priceCust3,
  discountCust3,
  priceWithDiscountCust3
) => `
mutation {
    addPrice(
        value:"${value}"
        discount:"${discount}"
        gasstation:"${gasstation}"
        productgas:"${product}"
        type:"${type}"
        priceWithDiscount:"${priceWithDiscount}"
        customer:"${customer}"
        priceDeb:"${priceDeb}"
        discountDeb:"${discountDeb}"
        priceCard:"${priceCar}"
        discountCard:"${discountCar}"
        priceCardWithDiscount:"${priceCreWithDiscount}"
        priceDebWithDiscount: "${priceDebWithDiscount}"

        priceCust1: ${priceCust1}
        discountCust1: ${discountCust1}
        priceWithDiscountCust1: ${priceWithDiscountCust1}

        priceCust2: ${priceCust2}
        discountCust2: ${discountCust2}
        priceWithDiscountCust2: ${priceWithDiscountCust2}

        priceCust3: ${priceCust3}
        discountCust3: ${discountCust3}
        priceWithDiscountCust3: ${priceWithDiscountCust3}
    ){
        _id
    }
}
`;
export const updateQueryPrice = (
  id,
  value,
  discount,
  gasstation,
  product,
  type,
  priceWithDiscount,
  customer,
  priceDeb,
  discountDeb,
  priceCar,
  discountCar,
  priceCreWithDiscount,
  priceDebWithDiscount,

  //REF 1
  priceCust1,
  discountCust1,
  priceWithDiscountCust1,

  //REF 2
  priceCust2,
  discountCust2,
  priceWithDiscountCust2,

  //REF 3
  priceCust3,
  discountCust3,
  priceWithDiscountCust3,
  userUpdate
) => `
mutation {
    updatePrice(
        _id:"${id}"
        value: ${value}
        discount: ${discount}
        gasstation:"${gasstation}"
        productgas:"${product}"
        type:"${type}"
        priceWithDiscount: ${priceWithDiscount}
        customer:"${customer}"
        priceDeb: ${priceDeb}
        discountDeb: ${discountDeb}
        priceCard: ${priceCar}
        discountCard: ${discountCar}
        priceCardWithDiscount: ${priceCreWithDiscount}
        priceDebWithDiscount: ${priceDebWithDiscount}

        priceCust1: ${priceCust1}
        discountCust1: ${discountCust1}
        priceWithDiscountCust1: ${priceWithDiscountCust1}

        priceCust2: ${priceCust2}
        discountCust2: ${discountCust2}
        priceWithDiscountCust2: ${priceWithDiscountCust2}

        priceCust3: ${priceCust3}
        discountCust3: ${discountCust3}
        priceWithDiscountCust3: ${priceWithDiscountCust3}
        userUpdate: "${userUpdate}"
    ){
        success message
    }
}
`;

export const GET_PRICES_INTEGRATION = gql`
  query getPricesIntegration($customer: ID!) {
    pricesIntegration(customer: $customer) {
      id_item
      description
      fidelity_group_denomination
      cnpj
      price_1
      price_2
      price_3
      price_4
      price_5
    }
  }
`;

export const getQueryPrice = id => `
{
    price(_id:"${id}"){
        _id  value discount  type priceWithDiscount priceDeb discountDeb priceCard discountCard priceCardWithDiscount priceDebWithDiscount createdAt
        adjustment adjustmentCard adjustmentDeb

        priceCust1
        discountCust1
        priceWithDiscountCust1
        adjustmentCust1

        priceCust2
        discountCust2
        priceWithDiscountCust2
        adjustmentCust2

        priceCust3
        discountCust3
        priceWithDiscountCust3
        adjustmentCust3

        gasstation {
          _id
          name
        }
        productgas {
          _id
          name
        }
    }
}
`;

export const getQueryPriceCustomer = customer => `
{
    pricesCustomer(
      customer:"${customer}"
    )
    {
      _id
      value
      discount
      priceWithDiscount
      type
      priceDeb
      discountDeb
      priceCard
      discountCard
      priceCardWithDiscount
      priceDebWithDiscount
      adjustment
      adjustmentCard
      adjustmentDeb
      createdAt
      updatedAt

      priceCust1
      discountCust1
      priceWithDiscountCust1
      adjustmentCust1

      priceCust2
      discountCust2
      priceWithDiscountCust2
      adjustmentCust2

      priceCust3
      discountCust3
      priceWithDiscountCust3
      adjustmentCust3

      gasstation{ _id name }
      productgas{ _id name active }
    }
}`;

export const deleteQueryPrice = id => `
mutation {
    removePrice(_id: "${id}")
    {
        success message
    }
}
`;

export const GET_PRICE = gql`
  query getPrice($_id: ID!) {
    price(_id: $_id) {
      _id
      value
      discount
      adjustment
      type
      priceWithDiscount
      priceDeb
      discountDeb
      adjustmentDeb
      priceCard
      discountCard
      priceCardWithDiscount
      adjustmentCard
      priceDebWithDiscount
      createdAt

      priceCust1
      discountCust1
      priceWithDiscountCust1
      adjustmentCust1

      priceCust2
      discountCust2
      priceWithDiscountCust2
      adjustmentCust2

      priceCust3
      discountCust3
      priceWithDiscountCust3
      adjustmentCust3

      priceCust4
      discountCust4
      priceWithDiscountCust4
      adjustmentCust4

      priceCust5
      discountCust5
      priceWithDiscountCust5
      adjustmentCust5

      priceCust6
      discountCust6
      priceWithDiscountCust6
      adjustmentCust6
      decimal

      fidelityMethod

      gasstation {
        _id
        name
      }
      productgas {
        _id
        name
      }
    }
  }
`;

const inputFields = `
  $gasstation: ID
  $productgas: ID
  $type: String
  $customer: ID
  $userUpdate: ID
  $user: ID

  $value: Float,
  $discount: Float,
  $priceWithDiscount: Float
  $nivel: String
  $adjustment: Float

  $priceCard: Float
  $discountCard: Float
  $priceCardWithDiscount: Float
  $nivelCard: String
  $adjustmentCard: Float

  $priceDeb: Float
  $discountDeb: Float
  $priceDebWithDiscount: Float
  $nivelDeb: String
  $adjustmentDeb: Float

  $priceCust1: Float
  $discountCust1: Float
  $priceWithDiscountCust1: Float
  $adjustmentCust1: Float

  $priceCust2: Float
  $discountCust2: Float
  $priceWithDiscountCust2: Float
  $adjustmentCust2: Float

  $priceCust3: Float
  $discountCust3: Float
  $priceWithDiscountCust3: Float
  $adjustmentCust3: Float

  $priceCust4: Float
  $discountCust4: Float
  $priceWithDiscountCust4: Float
  $adjustmentCust4: Float

  $priceCust5: Float
  $discountCust5: Float
  $priceWithDiscountCust5: Float
  $adjustmentCust5: Float

  $priceCust6: Float
  $discountCust6: Float
  $priceWithDiscountCust6: Float
  $adjustmentCust6: Float


  $decimal: Int
  $fidelityMethod: String
`;

const variables = `
  gasstation: $gasstation
  productgas: $productgas
  type: $type
  customer: $customer
  userUpdate: $userUpdate
  user: $user

  value: $value,
  discount: $discount,
  priceWithDiscount: $priceWithDiscount
  nivel: $nivel
  adjustment: $adjustment

  priceCard: $priceCard
  discountCard: $discountCard
  priceCardWithDiscount: $priceCardWithDiscount
  nivelCard: $nivelCard
  adjustmentCard: $adjustmentCard

  priceDeb: $priceDeb
  discountDeb: $discountDeb
  priceDebWithDiscount: $priceDebWithDiscount
  nivelDeb: $nivelDeb
  adjustmentDeb: $adjustmentDeb

  priceCust1: $priceCust1
  discountCust1: $discountCust1
  priceWithDiscountCust1: $priceWithDiscountCust1
  adjustmentCust1: $adjustmentCust1

  priceCust2: $priceCust2
  discountCust2: $discountCust2
  priceWithDiscountCust2: $priceWithDiscountCust2
  adjustmentCust2: $adjustmentCust2

  priceCust3: $priceCust3
  discountCust3: $discountCust3
  priceWithDiscountCust3: $priceWithDiscountCust3
  adjustmentCust3: $adjustmentCust3

  priceCust4: $priceCust4
  discountCust4: $discountCust4
  priceWithDiscountCust4: $priceWithDiscountCust4
  adjustmentCust4: $adjustmentCust4

  priceCust5: $priceCust5
  discountCust5: $discountCust5
  priceWithDiscountCust5: $priceWithDiscountCust5
  adjustmentCust5: $adjustmentCust5

  priceCust6: $priceCust6
  discountCust6: $discountCust6
  priceWithDiscountCust6: $priceWithDiscountCust6
  adjustmentCust6: $adjustmentCust6

  decimal: $decimal
  fidelityMethod: $fidelityMethod
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice($_id: ID!, ${inputFields}) {
    updatePrice(_id: $_id, ${variables}) {
      success
    }
  }
`;

export const ADD_PRICE = gql`
  mutation addPrice(${inputFields}) {
    addPrice(${variables}) {
      _id
    }
  }
`;

export const SET_CUSTOMER_PRICES = gql`
  mutation setCustomerPrices($customer: ID!) {
    setCustomerPrices(customer: $customer)
  }
`;
