import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { CancelButton, CheckButton } from '../Form/Button';

const ActionModal = ({
  isOpen,
  title,
  message,
  messageItemsList,
  callbackClose,
  callbackConfirm,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar'
}) => {
  return (
    <Modal isOpen={isOpen} toggle={callbackClose}>
      <ModalHeader toggle={callbackClose} charCode='X'>
        {title}
      </ModalHeader>
      <ModalBody>
        <p>{message}</p>
        {messageItemsList && messageItemsList.length > 0 && (
          <ul>
            {messageItemsList.map(item => (
              <li>{item}</li>
            ))}
          </ul>
        )}
      </ModalBody>
      <ModalFooter>
        <CheckButton
          onClick={() => {
            callbackConfirm();
            callbackClose();
          }}
        >
          {confirmLabel}
        </CheckButton>{' '}
        <CancelButton onClick={callbackClose}>{cancelLabel}</CancelButton>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ActionModal);
