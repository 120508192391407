import { request as graphql } from 'graphql-request';
import {
  addQueryProduct,
  getQueryProductStore,
  deleteQueryProduct,
  getQueryProducts,
  updateQueryProduct,
  getQueryListProduct
} from '../queries/produtcs';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_PRODUCT: 'product/SET',
  SET_ERROR: 'product/ERROR',
  SET_SUCCESS: 'product/SUCCESS'
};

const INITIAL_STATE = [];

export default function product(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PRODUCT:
      return action.product;
    case Types.SET_ERROR:
      return action.error;
    case Types.SET_SUCCESS:
      return action.success;
    default:
      return state;
  }
}

const setProduct = product => ({
  type: Types.SET_PRODUCT,
  product
});

const setError = error => ({
  type: Types.SET_ERROR,
  error
});
const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setProduct,
  setSuccess,
  setError,
  addProduct: (
    name,
    image,
    store,
    categories,
    images,
    ref,
    price,
    priceDes,
    description,
    customer,
    unit,
    destaque
  ) => {
    return dispatch => {
      graphql(
        config.URL_API,
        addQueryProduct(
          name,
          image,
          store,
          categories,
          images,
          ref,
          price,
          priceDes,
          description,
          customer,
          unit,
          destaque
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          // ////console.log('RESULTADO >>> ', data);
          const { addProduct } = data;

          if (addProduct._id) {
            dispatch(setSuccess({ success: 'Produto cadastrado com sucesso' }));
          } else {
            dispatch(
              setError({
                error:
                  'Houve um erro ao cadastrar o produto, favor tentar novamente'
              })
            );
          }
        })
        .catch(error => {
          ////console.log('ERROR >>', error);
          dispatch(setError({ error: 'Houve um erro ao cadastrar o produto' }));
        });
    };
  },

  getProductStore: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryProductStore(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { productsStore } = data;

          dispatch(setProduct(productsStore));
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve um erro ao carregar os produtos' })
          );
        });
    };
  },
  getProduct: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryProducts(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { product } = data;

          dispatch(setProduct(product));
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve um erro ao carregar os produtos' })
          );
        });
    };
  },

  deleteProduct: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        deleteQueryProduct(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.removeProduct;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao deletar o produto' }));
        });
    };
  },
  updateProduct: (
    id,
    name,
    image,
    store,
    categories,
    images,
    ref,
    price,
    priceDes,
    description,
    unit,
    destaque
  ) => {
    return dispatch => {
      // ////console.log(categories.length);
      graphql(
        config.URL_API,
        updateQueryProduct(
          id,
          name,
          image,
          store,
          categories,
          images,
          ref,
          price,
          priceDes,
          description,
          unit,
          destaque
        ),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updateProduct;

          if (success) {
            dispatch(setSuccess({ success: 'Produto atualizado com sucesso' }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao cadastrar o produto' }));
        });
    };
  },
  getListProduct: customer => {
    return dispatch => {
      graphql(
        config.URL_API,
        getQueryListProduct(customer),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { productsCustomer } = data;

          dispatch(setProduct(productsCustomer));
        })
        .catch(error => {
          dispatch(
            setError({ error: 'Houve um erro ao carregar os produtos' })
          );
        });
    };
  }
};
