/* eslint-disable no-use-before-define */
import { request as graphql } from 'graphql-request';
import {
  GetQueryProductGas,
  AddQueryProductGas,
  InactivateQueryProductGas,
  ActivateQueryProductGas,
  GetProductGasId,
  UpdateProductGas
} from '../queries/productgas';

import config, { authGraphqlHeaders } from '../../config';

export const Types = {
  SET_PRODUCTGAS: 'productgas/SET',
  SET_ERROR: 'productgas/ERROR',
  SET_SUCCESS: 'productgas/SUCCESS'
};

const INITIAL_STATE = [];

export default function productgas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PRODUCTGAS:
      return action.productgas;
    case Types.SET_SUCCESS:
      return action.success;
    case Types.SET_ERROR:
      return action.error;
    default:
      return state;
  }
}

const setProductGas = productgas => ({
  type: Types.SET_PRODUCTGAS,
  productgas
});
const setError = error => ({
  type: Types.SET_ERROR,
  error
});

const setSuccess = success => ({
  type: Types.SET_SUCCESS,
  success
});

export const Creators = {
  setProductGas,
  setError,
  setSuccess,
  getProductGas: customer => {
    return dispatch => {
      graphql(
        config.URL_API,
        GetQueryProductGas(customer),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          //   ////console.log(productgasCustomer, data);
          const { productgasCustomer } = data;
          dispatch(setProductGas(productgasCustomer));
        })
        .catch(error => {
          dispatch(setError({ error: 'Houve um erro ao buscar os postos' }));
        });
    };
  },
  addProductGas: (name, icon, customer, ref) => {
    return dispatch => {
      graphql(
        config.URL_API,
        AddQueryProductGas(name, icon, customer, ref),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { _id } = data.addProductGas;

          if (_id) {
            dispatch(
              setSuccess({ success: 'Produto cadastrado com sucesso!' })
            );
          } else {
            dispatch(
              setError({ error: 'Houve um erro ao cadastrar o produto' })
            );
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao cadastrar o produto' }))
        );
    };
  },
  updateProductGas: (id, name, icon, customer, ref) => {
    return dispatch => {
      graphql(
        config.URL_API,
        UpdateProductGas(id, name, icon, customer, ref),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.updateProductGas;
          ////console.log(success);
          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao atulizar o produto' }))
        );
    };
  },
  inactivateProductGas: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        InactivateQueryProductGas(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.removeProductGas;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao cadastrar os postos' }))
        );
    };
  },
  activateProductGas: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        ActivateQueryProductGas(id),
        undefined,
        authGraphqlHeaders().headers
      )
        .then(data => {
          const { success, message } = data.activateProductGas;

          if (success) {
            dispatch(setSuccess({ success: message }));
          } else {
            dispatch(setError({ error: message }));
          }
        })
        .catch(error =>
          dispatch(setError({ error: 'Houve um erro ao cadastrar os postos' }))
        );
    };
  },
  getProductGasId: id => {
    return dispatch => {
      graphql(
        config.URL_API,
        GetProductGasId(id),
        undefined,
        authGraphqlHeaders().headers
      ).then(data => {
        const { _id } = data.productgas;

        if (_id) {
          dispatch(setProductGas(data.productgas));
        } else {
          dispatch(setError({ error: 'Não foi possível encontrar o produto' }));
        }
      });
    };
  }
};
