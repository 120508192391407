/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
// import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Loadable from 'react-loadable';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import { routes } from './routes';

// setup fake backend
import { configureFakeBackend } from './helpers';
import { isUserAuthenticated } from './helpers/authUtils';

// CSS padrão do Toastify - Adicionado por MAVO em 29/07/2020 as 13:30
import 'react-toastify/dist/ReactToastify.css';

// Themes
import './assets/scss/DefaultTheme.scss';
import config from './config';

import { AlertModalProvider } from './contexts/AlertModalContext/index';
// import ReleaseModal from './components/Release';

window.Buffer = window.Buffer || require('buffer').Buffer;

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.locale('pt-br');
// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import('./components/NonAuthLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});

const AuthLayout = Loadable({
  loader: () => import('./components/AuthLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return (
      <>
        {/* <ReleaseModal /> */}
        <Component {...props} />
      </>
    );
  },
  loading
});

// configure fake backend
// configureFakeBackend();

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = WrappedComponent => {
  function HOC(props) {
    return <WrappedComponent {...props} />;
  }
  return connect()(HOC);
};

/**
 * Main app component
 */
export function App() {
  function getLayout() {
    return isUserAuthenticated() ? AuthLayout : NonAuthLayout;
  }

  return (
    // rendering the router with layout
    <BrowserRouter>
      <React.Fragment>
        <AlertModalProvider>
          {routes.map((route, index) => {
            return (
              <route.route
                key={index}
                path={route.path}
                exact={route.exact}
                roles={route.roles}
                component={withLayout(props => {
                  const Layout = getLayout();
                  return (
                    <>
                      <Suspense fallback={loading()}>
                        <Layout {...props} title={route.title}>
                          <route.component {...props} />
                        </Layout>
                      </Suspense>
                    </>
                  );
                })}
              />
            );
          })}
        </AlertModalProvider>
        <ToastContainer
          autoClose={config.TOAST_AUTO_CLOSE}
          hideProgressBar={config.TOAST_HIDE_PROGRESS_BAR}
          closeOnClick={config.TOAST_CLOSE_ON_CLICK}
          position='top-center'
        />
      </React.Fragment>
    </BrowserRouter>
  );
}
