import React, { useState, useEffect } from 'react';
import { Card, CardBody, Input, Form, FormGroup, Label } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import ButtonsSaveClose from '../../../../components/ButtonsSaveClose';
import { useMutation } from '@apollo/client';
import { SEND_SMS_NOTIFICATION } from '../../../../store/queries/message';

import { toast } from 'react-toastify';
import ActionModal from '../../../../components/ActionModal';
import { connect } from 'react-redux';

const SendSms = ({ user }) => {
  const { id } = useParams();
  const history = useHistory();

  const [text, setText] = useState('');
  const [clients, setClients] = useState([]);

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const toggleAlert = () => setShowConfirmAlert(!showConfirmAlert);

  const [sendSMS] = useMutation(SEND_SMS_NOTIFICATION);

  useEffect(() => {
    if (id) {
      setClients(id.split('&'));
    }
  }, [id]);

  const goBack = () => history.goBack();
  const sendNotification = () => {
    sendSMS({
      variables: {
        title: '',
        message: text,
        customer: user.customer,
        client: clients,
        user: user.id
      }
    });
  };

  function confirmAlert() {
    if (!text) {
      toast.error('Favor preencher o texto');
      return;
    }

    setShowConfirmAlert(true);
  }

  return (
    <>
      <ActionModal
        isOpen={showConfirmAlert}
        title='Confirmar Envio'
        message='Deseja realmente enviar a notificação aos clientes?'
        messageItemsList={[
          <span>
            <b>Quantidade de Clientes: </b>
            {clients.length}
          </span>,
          <span>
            <b>Texto: </b>
            {text}
          </span>
        ]}
        callbackClose={toggleAlert}
        callbackConfirm={sendNotification}
      />

      <div>
        <Card>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Texto</Label>
                <Input
                  type='textarea'
                  value={text}
                  onChange={e => setText(e.target.value)}
                />

                <br />

                <ButtonsSaveClose
                  toggle={null}
                  labelSave={'Enviar'}
                  labelBack={'Fechar'}
                  onSave={() => confirmAlert()}
                  onBack={() => goBack()}
                />
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SendSms);
