import { combineReducers } from 'redux';

import user from './user';
import customer from './customer';
import store from './store';
import category from './category';
import userstore from './userStore';
import product from './products';
import notification from './notification';
import gasstation from './gasstation';
import productgas from './productgas';
import price from './price';
import promotions from './promotions';
import payments from './payments';

export default combineReducers({
  user,
  customer,
  store,
  category,
  userstore,
  product,
  notification,
  gasstation,
  productgas,
  price,
  promotions,
  payments
});
