import { GraphQLClient } from 'graphql-request';
import jwtDecode from 'jwt-decode';
import { refreshUserTokenQuery } from '../store/queries/user';
import store from '../store';
import { Creators as actionUser } from '../store/ducks/user';

const config = {
  ENV: 'test',
  URL_API: process.env.REACT_APP_API,
  TOAST_AUTO_CLOSE: 3000,
  TOAST_HIDE_PROGRESS_BAR: false,
  TOAST_CLOSE_ON_CLICK: true
};

const tokenExpired = token => {
  const decoded = jwtDecode(token);
  const now = Date.now() / 1000;
  return decoded.exp < now;
};

export const refreshToken = async () => {
  const {
    token: oldToken,
    refreshToken: oldRefreshToken,
    id: userId
  } = JSON.parse(localStorage.getItem('user'));

  if (tokenExpired(oldToken)) {
    if (tokenExpired(oldRefreshToken)) {
      store.dispatch(actionUser.logout());
      window.location.pathname = '/login';
    }

    const graphQL = new GraphQLClient(config.URL_API);
    const {
      refreshUserToken: { user, token, refreshToken }
    } = await graphQL.request(refreshUserTokenQuery(userId, oldRefreshToken));

    const dataJs = {
      id: user._id,
      username: user.email,
      firstName: user.name,
      imagePerfil: '',
      customer: user.customer ? user.customer._id : null,
      role: user.role,
      type: null,
      store: user.store,
      token,
      refreshToken
    };

    localStorage.setItem('user', JSON.stringify(dataJs));

    return dataJs;
  }
};

export const authGraphqlHeaders = headers => {
  const user = localStorage.getItem('user');
  if (user) {
    const { token } = JSON.parse(user);

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  }
};

export default config;
