import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { FilterList } from '@material-ui/icons';
import { ActivateItem, EditValueItem, InactivateItem } from '../ActionItems';

/**
 *
 * @param {import('material-table').MaterialTableProps<RowData>} props
 */
export default ({
  options,
  onOrderChange,
  onSearchChange,
  sortableColumns,
  columns,
  data,
  rightTitle,
  callbackFunctionInativar,
  callbackFunctionAtivar,
  callbackFunctionEditValues,
  useServerSideFiltering = false,
  ...rest
}) => {
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);

  return (
    <MaterialTable
      style={{
        boxShadow: 'unset',
        margin: 0,
        zIndex: 0,
        fontFamily: 'Nunito',
        ...rest.style
      }}
      data={data}
      onOrderChange={(indexOrderBy, direction) => {
        const selectedColumn = sortableColumns && sortableColumns[indexOrderBy];
        if (selectedColumn) {
          return onOrderChange(selectedColumn, direction);
        }
      }}
      onSelectionChange={rows => {
        setActiveCount(rows.filter(x => x.active === 'Sim').length);
        setInactiveCount(rows.filter(x => x.active === 'Não').length);
        if (rest && rest.onSelectionChange) {
          rest.onSelectionChange(rows);
        }
      }}
      columns={columns}
      onSearchChange={text => onSearchChange && onSearchChange(text.trim())}
      options={{
        paging: true,
        exportButton: false,
        emptyRowsWhenPaging: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100],
        debounceInterval: 750,
        padding: 'dense',
        rowStyle: (row, index) => ({
          backgroundColor: index % 2 ? '#FFF' : '#409df70f',
          fontWeight: row.totalRow ? 'bolder' : 'normal',
          color:
            row.active === 'Não'
              ? 'red'
              : row.future === true
              ? 'green'
              : row.disabled === true
              ? 'gray'
              : 'black'
        }),
        ...options
      }}
      actions={[
        options && options.selection && callbackFunctionEditValues
          ? {
              tooltip: 'Editar registros selecionados',
              icon: () => <EditValueItem />,
              onClick: (evt, data) => {
                callbackFunctionEditValues(data);
              }
            }
          : null,
        options && options.selection && activeCount > 0 && !inactiveCount
          ? {
              tooltip: 'Inativar registros selecionados',
              icon: () => <InactivateItem />,
              onClick: (evt, data) => {
                callbackFunctionInativar(data);
              }
            }
          : null,
        options && options.selection && inactiveCount > 0 && !activeCount
          ? {
              tooltip: 'Ativar registros selecionados',
              icon: () => <ActivateItem />,
              onClick: (evt, data) => {
                callbackFunctionAtivar(data);
              }
            }
          : null
      ]}
      icons={{
        Search: () => <FilterList />
      }}
      localization={{
        pagination: {
          labelRowsPerPage: 'Linhas por Página:',
          labelRowsSelect: 'linhas',
          labelDisplayedRows: '{from}-{to} de {count}',
          firstAriaLabel: 'Primeira Página',
          firstTooltip: 'Primeira Página',
          previousAriaLabel: 'Página Anterior',
          previousTooltip: 'Página Anterior',
          nextAriaLabel: 'Pŕoxima Página',
          nextTooltip: 'Pŕoxima Página',
          lastAriaLabel: 'Última Página',
          lastTooltip: 'Última Página',
          ...(options && options.localization && options.localization.pagination
            ? options.localization.pagination
            : {})
        },
        toolbar: {
          searchPlaceholder: 'Pesquisar',
          searchTooltip: 'Pesquisar',
          nRowsSelected: '{0} linha(s) selecionada(s)',
          addRemoveColumns: 'Adicionar ou remover colunas',
          showColumnsTitle: 'Exibir colunas',
          showColumnsAriaLabel: 'Exibir colunas',
          exportTitle: 'Exportar',
          exportAriaLabel: 'Exportar',
          exportName: 'Exportar para .CSV',
          searchTooltip: 'Pesquisar',
          searchPlaceholder: 'Pesquisar'
        },
        grouping: {
          groupedBy: 'Agrupado por:',
          placeholder: 'Arraste os cabeçalhos aqui para agrupar por:'
        },
        body: {
          emptyDataSourceMessage: 'Nenhum registro encontrado',
          filterRow: {
            filterTooltip: 'Filtrar'
          }
        }
      }}
      {...rest}
    />
  );
};
