import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

function SelectAgeRange({
  isMulti,
  label,
  placeholder,
  parentCallback,
  disable,
  value,
  selectAllOption = false
}) {
  let [options, setOptions] = useState([]);

  useEffect(() => {
    const newOpts = [];
    if (selectAllOption) {
      newOpts.push({
        value: 'todos',
        label: 'Todas as Faixas Etárias'
      });
    }
    newOpts.push(
      {
        value: 'DE_18_A_24_ANOS',
        label: 'De 18 a 24 anos'
      },
      {
        value: 'DE_25_A_34_ANOS',
        label: 'De 25 a 34 anos'
      },
      {
        value: 'DE_35_A_44_ANOS',
        label: 'De 35 a 44 anos'
      },
      {
        value: 'DE_45_A_54_ANOS',
        label: 'De 45 a 54 anos'
      },
      {
        value: 'DE_55_A_64_ANOS',
        label: 'De 55 a 64 anos'
      },
      {
        value: 'DE_65_ANOS_OU_MAIS',
        label: '65 anos ou mais'
      }
    );
    const selectedAll = isMulti
      ? value.some(x => x.value === 'todos')
      : value.value === 'todos';

    setOptions(newOpts.map(item => ({ ...item, isDisabled: selectedAll })));
  }, [selectAllOption]);

  function handleChange(opt) {
    if (isMulti) {
      const selectedAll = opt.some(x => x.value === 'todos');
      setOptions(options.map(item => ({ ...item, isDisabled: selectedAll })));

      parentCallback(selectedAll ? opt.filter(x => x.value === 'todos') : opt);
    } else {
    }
  }

  return (
    <FormGroup>
      {label && <Label for='month'> {label} </Label>}
      <Select
        id='month'
        isMulti={isMulti}
        closeMenuOnSelect={isMulti ? false : true}
        onChange={handleChange}
        isSearchable={true}
        isDisabled={disable}
        value={value}
        placeholder={placeholder || label}
        options={options}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
      />
    </FormGroup>
  );
}

export default SelectAgeRange;
