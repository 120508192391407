import { gql } from '@apollo/client';

export const GetQueryGasStation = customer => `
{
    gasstationsCustomer(customer:  "${customer}"){
      _id name cnpj flag flagImage evaluation clientrated createdAt updatedAt active
    }
}
`;

export const AddQueryGasStation = (
  name,
  cnpj,
  companyName,
  flag,
  city,
  cep,
  uf,
  address,
  phone,
  facebook,
  instagram,
  site,
  whatsapp,
  N,
  lat,
  lng,
  image,
  customer,
  productGas,
  neighborhood,
  timezone
) => `
mutation{
    addGasStation(
        name: "${name}"
        cnpj: "${cnpj}"
        companyName: "${companyName}"
        flag: "${flag}"
        city: "${city}"
        cep: "${cep}"
        uf: "${uf}"
        address:"${address}"
        phone: "${phone}"
        facebook:"${facebook}"
        instagram:"${instagram}"
        site:"${site}"
        whatsapp:"${whatsapp}"
        N:"${N}"
        lat:"${lat}"
        lng:"${lng}"
        image:"${image}"
        customer:"${customer}"

        timezone: ${timezone}
        productGas: [${
          productGas.length > 0
            ? productGas.map(item => '"' + item.value + '"')
            : null
        }]
        neighborhood: "${neighborhood}"
        active: ${true}

    ){
        _id error
    }
}
`;

export const deleteQueryGasStation = id => `
mutation{
    removeGasStation(_id: "${id}"){
        success message
    }
}
`;

export const UPDATE_GAS_STATION_DEVICES = gql`
  mutation handleGasDevices($id: ID, $atendenteCode: [appAtendenteCodeInput]) {
    updateGasStation(_id: $id, atendenteCode: $atendenteCode) {
      data
      message
      stackTrace
      success
    }
  }
`;

export const UpdateQueryGasStation = (
  id,
  name,
  cnpj,
  companyName,
  flag,
  city,
  cep,
  uf,
  address,
  phone,
  facebook,
  instagram,
  site,
  whatsapp,
  N,
  lat,
  lng,
  image,
  customer,
  productGas,
  neighborhood,

  timezone
) => `
mutation{
    updateGasStation(
        _id:"${id}"
        name: "${name}"
        cnpj: "${cnpj}"
        companyName: "${companyName}"
        flag: "${flag}"
        city: "${city}"
        cep: "${cep}"
        uf: "${uf}"
        address:"${address}"
        phone: "${phone}"
        facebook:"${facebook}"
        instagram:"${instagram}"
        site:"${site}"
        whatsapp:"${whatsapp}"
        N:"${N}"
        lat:"${lat}"
        lng:"${lng}"
        image:"${image}"
        customer:"${customer}"
        timezone: ${timezone}
        productGas: [${
          productGas.length > 0
            ? productGas.map(item => '"' + item.value + '"')
            : null
        }]
        neighborhood: "${neighborhood}"

    ){
        success message
    }
}
`;

export const GetQuerGasStationId = id => `
{
    gasstation(_id: "${id}"){
        _id
name
active
cnpj
companyName
flag
flagImage
city
cep
uf
address
phone
convenience
oilChange
N
lat
lng
image
facebook
instagram
site
timezone
whatsapp
productGas{
    _id name
}
evaluation
neighborhood
    }
}
`;

export const GET_GASSTATIONS_CUSTOMER = gql`
  query getGasstations($customer: ID) {
    gasstationsDashboard(customer: $customer) {
      _id
      name
      cnpj
      flag
      flagImage
      evaluation
      clientrated
      createdAt
      updatedAt
      active
      user {
        name
      }
      userUpdate {
        name
      }
    }
  }
`;

export const GET_ACTIVE_GASSTATIONS_CUSTOMER = gql`
  query getActiveGasstations($customer: ID) {
    gasstationsCustomer(customer: $customer) {
      _id
      name
      cnpj
      flag
      flagImage
      evaluation
      clientrated
      createdAt
      timezone
      updatedAt
      active
      atendenteCode {
        code
        name
      }
    }
  }
`;

export const INACTIVATE_GASSTATION = gql`
  mutation inactivateGasStation($_id: ID) {
    removeGasStation(_id: $_id) {
      success
      message
    }
  }
`;

export const ACTIVATE_GASSTATION = gql`
  mutation activateGasStation($_id: ID) {
    activateGasStation(_id: $_id) {
      success
      message
    }
  }
`;

export const GET_GASSTATION = gql`
  query getGasstation($_id: ID!) {
    gasstation(_id: $_id) {
      _id
      name
      active
      cnpj
      companyName
      flag
      flagImage
      city
      cep
      uf
      address
      phone
      convenience
      hiddeninApp
      oilChange
      N
      lat
      lng
      image
      facebook
      instagram
      site
      timezone
      whatsapp
      productGas {
        _id
        name
      }
      denyPayments {
        _id
        name
      }
      evaluation
      neighborhood
    }
  }
`;

export const ADD_GASSTATION = gql`
  mutation addGasstation(
    $name: String
    $cnpj: String
    $companyName: String
    $flag: String
    $flagImage: String
    $city: String
    $cep: String
    $uf: String
    $address: String
    $phone: String
    $facebook: String
    $instagram: String
    $site: String
    $whatsapp: String
    $N: String
    $lat: String
    $lng: String
    $image: String
    $customer: ID
    $productGas: [ID]
    $denyPayments: [ID]
    $neighborhood: String
    $timezone: Int
    $user: ID
    $hiddeninApp: Boolean
  ) {
    addGasStation(
      name: $name
      cnpj: $cnpj
      companyName: $companyName
      flag: $flag
      flagImage: $flagImage
      city: $city
      cep: $cep
      uf: $uf
      address: $address
      phone: $phone
      facebook: $facebook
      instagram: $instagram
      site: $site
      whatsapp: $whatsapp
      N: $N
      lat: $lat
      lng: $lng
      image: $image
      customer: $customer
      productGas: $productGas
      denyPayments: $denyPayments
      neighborhood: $neighborhood
      timezone: $timezone
      user: $user
      hiddeninApp: $hiddeninApp
    ) {
      _id
      success
      message
    }
  }
`;

export const UPDATE_GASSTATION = gql`
  mutation updateGasstation(
    $_id: ID
    $name: String
    $cnpj: String
    $companyName: String
    $flag: String
    $flagImage: String
    $city: String
    $cep: String
    $uf: String
    $address: String
    $phone: String
    $facebook: String
    $instagram: String
    $site: String
    $whatsapp: String
    $N: String
    $lat: String
    $lng: String
    $image: String
    $customer: ID
    $productGas: [ID]
    $denyPayments: [ID]
    $neighborhood: String
    $timezone: Int
    $userUpdate: ID
    $hiddeninApp: Boolean
  ) {
    updateGasStation(
      _id: $_id
      name: $name
      cnpj: $cnpj
      companyName: $companyName
      flag: $flag
      flagImage: $flagImage
      city: $city
      cep: $cep
      uf: $uf
      address: $address
      phone: $phone
      facebook: $facebook
      instagram: $instagram
      site: $site
      whatsapp: $whatsapp
      N: $N
      lat: $lat
      lng: $lng
      image: $image
      customer: $customer
      productGas: $productGas
      denyPayments: $denyPayments
      neighborhood: $neighborhood
      timezone: $timezone
      userUpdate: $userUpdate
      hiddeninApp: $hiddeninApp
    ) {
      success
      message
    }
  }
`;

export const LIST_NOZZLE_BY_CUSTOMER = gql`
  query ConcentradoresBomba($active: Boolean, $customer: ID) {
    gasstationsCustomer(customer: $customer) {
      concentradoresBomba(active: $active) {
        idConcentradorBomba
        nomeConcentrador
        gasstation {
          _id
          name
        }
        gasStationMap {
          numeroBico
          codigoHexadecimal
          _id
        }
      }
    }
  }
`;
