import { gql } from '@apollo/client';

export const authUserQuery = (email, password, platformType) =>
  `{
      authUser(email: "${email}" password: "${password}" platformType: ${platformType}) {
        token refreshToken user{
          name _id email role store{
            _id name
          } customer {
            _id  processManual
          }
          distributor{
            _id name
          }
        }
      }
    }`;

export const CHANGE_ADMIN_CUSTOMER = gql`
  mutation changeAdminCustomer($customer: ID!) {
    changeAdminCustomer(customer: $customer)
  }
`;

export const GET_USER_DROPDOWN = gql`
  query getUserDropdown($customer: ID!) {
    userCustomer(customer: $customer) {
      _id
      name
    }
  }
`;

export const CHANGE_STATUS_USER = gql`
  mutation UpdateUserStatus($id: ID!, $active: Boolean!) {
    updateUserStatus(_id: $id, active: $active) {
      success
      message
      data
      stackTrace
    }
  }
`;

export const getQueryUser = id => `
{
  userCustomer(customer: "${id}"){
    _id name email active createdAt updatedAt role distributor{
      name _id
    }
    store{
      _id name
    }
  }
}`;

export const getQueryUsers = () => `
{
  users(platformType: PRIVATE){
    _id name email createdAt updatedAt role platformType active
    distributor{
      name _id
    }
    store{
      _id name
    }
    customer{ _id name processManual}
  }
}`;

export const removeQueryUser = id => `
  mutation{
    removeUser(_id: "${id}"){
      success message
    }
  }`;

export const getQueryCustomer = id => `
  {
    customer(_id:"${id}"){
      _id name type processManual
    }
  }
  `;

export const updateUserData = (id, name) => `
  mutation{
    updateUser(_id: "${id}", name: "${name}"){
      success
      message
      data
    }
  }
`;

export const refreshUserTokenQuery = (_id, refreshToken) => `{
  refreshUserToken(_id: "${_id}" refreshToken: "${refreshToken}") {
    token refreshToken
    user {
      name _id email role
      store { _id name }
      customer { _id type }
      distributor {_id name}
    }
  }
}`;

export const GET_CUSTOMER = gql`
  query getCustomer($_id: ID!) {
    customer(_id: $_id) {
      _id
      name
      type
      processManual
    }
  }
`;

export const GET_USER = gql`
  query getUser($_id: ID!) {
    user(_id: $_id) {
      _id
      name
      email
      role
      active
      customer {
        _id
        processManual
        name
      }
      createdAt
      updatedAt
      store {
        _id
        name
      }
      gasstation {
        _id
        name
      }
      distributor {
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String
    $email: String
    $customer: ID
    $store: ID
    $role: String
    $gasstation: ID
    $distributor: ID
  ) {
    updateUser(
      _id: $id
      email: $email
      name: $name
      customer: $customer
      store: $store
      role: $role
      gasstation: $gasstation
      distributor: $distributor
    ) {
      success
      message
      data
    }
  }
`;

export const UPDATE_USERPASS = gql`
  mutation UpdateUser(
    $id: ID
    $password: String
    $newPassword1: String
    $newPassword2: String
  ) {
    updateUser(
      _id: $id
      password: $password
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      message
      data
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser(
    $name: String
    $email: String
    $password: String
    $role: String
    $customer: ID
    $store: ID
    $gasstation: ID
    $distributor: ID
    $platformType: platformType!
  ) {
    addUser(
      name: $name
      email: $email
      password: $password
      role: $role
      customer: $customer
      store: $store
      gasstation: $gasstation
      distributor: $distributor
      platformType: $platformType
    ) {
      _id
      name
      message
      success
    }
  }
`;

export const setQueryUser = (name, email, password, customer, role, store) => `
  mutation {
    addUser(
      name: "${name}"
      email: "${email}"
      password: "${password}"
      customer: "${customer}"
      role: "${role}"
      store: "${store}"
    ){
      _id
      name
    }
  }
  `;

export const setQueryUserADMIN = (name, email, password, customer, role) => `
  mutation {
    addUser(
      name: "${name}"
      email: "${email}"
      password: "${password}"
      customer: "${customer}"
      role: "${role}"
    ){
      _id
      name
    }
  }
  `;

export const SEND_PASS = gql`
  mutation sendPass($email: String, $platformType: platformType) {
    sendPassword(email: $email, platformType: $platformType) {
      success
      message
      data
    }
  }
`;
