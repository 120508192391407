import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

function SelectGender({
  isMulti,
  label,
  placeholder,
  parentCallback,
  disable,
  value,
  selectAllOption = false
}) {
  let [options, setOptions] = useState([]);

  useEffect(() => {
    const newOpts = [];
    if (selectAllOption) {
      newOpts.push({
        value: 'todos',
        label: 'Todos'
      });
    }
    newOpts.push(
      {
        value: 'Masculino',
        label: 'Masculino'
      },
      {
        value: 'Feminino',
        label: 'Feminino'
      },
      {
        value: 'Prefiro não responder',
        label: 'Prefiro não responder'
      },
      {
        value: 'Outros',
        label: 'Outros'
      },
      {
        value: null,
        label: 'Não informado'
      }
    );
    const selectedAll = isMulti
      ? value.some(x => x.value === 'todos')
      : value.value === 'todos';

    setOptions(newOpts.map(item => ({ ...item, isDisabled: selectedAll })));
  }, [selectAllOption]);

  function handleChange(opt) {
    if (isMulti) {
      const selectedAll = opt.some(x => x.value === 'todos');
      setOptions(options.map(item => ({ ...item, isDisabled: selectedAll })));

      parentCallback(selectedAll ? opt.filter(x => x.value === 'todos') : opt);
    } else {
    }
  }

  return (
    <FormGroup>
      {label && <Label for='month'> {label} </Label>}
      <Select
        id='month'
        isMulti={isMulti}
        closeMenuOnSelect={isMulti ? false : true}
        onChange={handleChange}
        isSearchable={true}
        isDisabled={disable}
        value={value}
        placeholder={placeholder || label}
        options={options}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
      />
    </FormGroup>
  );
}

export default SelectGender;
